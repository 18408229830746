const httpUtils = require("./http-utils.js");
const users = require("./user-service.js");

function getEditings() {
    return new Promise((resolve, reject) => {
        users.me().then(user => {
            httpUtils._get(`/editings?filter[where][userId]=${user.user.id}`).then(data => {
                resolve(data);
            })
        }).catch(error => {
            reject(error);
        });
    });
}

function getEditingsInProgress() {
    return new Promise((resolve, reject) => {
        users.me().then(user => {
            httpUtils._get(`/editings?filter[where][userId]=${user.user.id}&filter[where][progress][lt]=100`).then(data => {
                resolve(data);
            })
        }).catch(error => {
            reject(error);
        });
    });
}

function getChangeStream(ondata, source, cb) {
    users.me().then(user => {
        var token = sessionStorage.getItem('user_token');
        var url = httpUtils.BASE_URL + '/editings/change-stream?_format=event-stream&access_token=' + token + '&options=' + encodeURIComponent(JSON.stringify({ where: { userId: user.userId } }));
        var evtSource = new EventSource(url);
        evtSource.addEventListener('data', (message) => {
            var obj = JSON.parse(message.data);
            ondata(obj.data);
            if (obj.data.progress == 100 && obj.data.status == 'FINISHED') {
                setTimeout(cb, 2000);
            }
        });
        source(evtSource);
    }).catch(error => {
        console.log(error);
    });
}

function getEffect(effectId) {
    return httpUtils._get(`/effects/${effectId}`);
}


module.exports = { getChangeStream, getEditings, getEffect, getEditingsInProgress }