<template>
  <div v-if=" texts && mediaGroup" class="media-group">
    <div class="title">
      <div ref="titleInfo" class="titleInfo" @click="showEditName">{{ mediaGroup.name }}  <span class="material-icons" >edit</span></div>
      <div ref="inputEditName" class="inputEditName">
        <videotek-input id="mediaNameInput" v-model="name" type="text" @keyup.enter="updateName"></videotek-input>
      </div>
      <span class="material-icons infos" @click="this.$emit('action', 'downloadFile')" v-tooltip.center="this.$i18n.translate(texts, 'infos')" >help</span>
    </div>
    <div id="video-div">
      <videotek-video-player actions :versionVideo="mediaGroup.medias" v-if="mediaPlayed" :sourceVideo="getPreview()"
            :typeVideo="this.mediaPlayed.type" @action="setAction" @mediaChanged="init" :duration="mediaGroup.duration">
            </videotek-video-player>
    </div>
    <videotek-modal v-if="modalConfig" :config="modalConfig" @execute="execute"></videotek-modal>
  </div>
</template>

<script>
import VideotekInput from './generics/videotek-input.vue';
import VideotekButton from "./generics/videotek-button.vue"
import VideotekModal from "./generics/videotek-modal.vue"
import VideotekVideoPlayer from "./video/videotek-video-player.vue"
import VideotekVideoComponent from "./video/videotek-video-component.vue"
import VTooltip from 'v-tooltip'

export default {
  name: "MediasList",
  props: {
    mediaGroup: Object,
    refresh: Boolean,
  },
  components: { VideotekInput, VideotekButton, VideotekModal, VideotekVideoPlayer, VideotekVideoComponent, VTooltip },
  emits: ["reloadGroups", "renameGroup"],
  data() {
    return {
      medias: [],
      collectionId: undefined,
      mediaPlayed: undefined,
      name: undefined,
      timer: undefined,
      modalConfig: {},
      texts: require('./videotek-media-group.json')
    };
  },
  watch: {
    mediaGroup: function (newVal, oldVal) {
      this.mediaPlayed = undefined;
      setTimeout(() => {
        this.init();
      }, 100);
    }
  },
  methods: {
    execute(action){
      this[action]();
    },
    init(media) {
      this.medias = this.mediaGroup.medias;
      this.mediaPlayed = (media) ? media : this.medias.filter((media) => media.version == "Base")[0];
      this.name = this.mediaGroup.name;
      sessionStorage.setItem('cached', JSON.stringify({id: this.mediaGroup.id}));
    },

    deleteMedia() {
      this.mediaPlayed = undefined; 
      delete sessionStorage.cached;
      this.$medias.deleteGroup(this.mediaGroup.id).then(() => {
        this.$emit("reloadGroups");
      });
    },
    copyMedia() {
      this.$medias.cloneGroup( this.mediaGroup.id, this.mediaPlayed.id).then(() => {
        this.$emit("reloadGroups");  
      });
    }, 
    setAction(action){
      this[action]();
    },
    downloadFile() {
      //direct download, use DownloadForm.vue later
      var url = this.$medias.getOriginal(this.mediaPlayed.id);
      var link = document.createElement("a");
      link.download = this.mediaPlayed.name;
      link.target = "_parent";
      link.href = url;
      document.body.appendChild(link);
      setTimeout(()=>{
          link.click();
          document.body.removeChild(link);
      }, 500);
    },
    updateName() {    
        if(this.name == ''){
          this.name = this.mediaGroup.name;
        }else{
          var name = this.name;
          var id = this.mediaGroup.id;
          this.$medias.updateGroup(id, { name }).then(()=>{
             this.$emit("renameGroup", id, name);
          }); 
          this.mediaGroup.name = this.name;
        }       
        this.$refs["inputEditName"].style.display = "none";
        this.$refs["titleInfo"].style.display = "inline-block";
    },
    getPreview() {
      return this.$medias.getPreview(this.mediaPlayed.id);
    },
    showEditName() {
      let input = this.$refs["inputEditName"];
      let title = this.$refs["titleInfo"];
      title.style.display = "none";
      input.style.display = "inline-block";
      document.getElementById("mediaNameInput").focus();
    },
    deleteFile() {
      var self = this;
      this.modalConfig = {
        name: 'delete',
        show: true,
        text: self.$i18n.translate(self.texts, 'deleteFile'),
        validate: 'deleteMedia',
        canceled: true
      }
    },
    copyFile(){
      var self = this;
      this.modalConfig = {
        name: 'copy',
        show: true,
        text: self.$i18n.translate(self.texts, 'copyFile'),
        validate: 'copyMedia',
        canceled: true
      }
    },
    editFile(){
      sessionStorage.setItem("media", JSON.stringify(this.mediaPlayed));
      this.$router.push("/edit");
    }
  },
  mounted() {
    this.texts = require('./videotek-media-group.json');
    this.init();
  },
};
</script>

<style scoped lang="scss" >
@import "../assets/style.scss";

.media-group{
  margin-left: 20px;
  margin-right: 20px;
  flex: 3;
  min-width: 375px;
  background-color: $background-dark;
  font-family: $font-family;
  overflow: auto;
  border-radius: 5px;
}

.title{
  width: auto;
  background-color: $primary-dark;
  color: white;
  padding: 5px 5px 5px 10px;
  font-weight: 600;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.material-icons{
  font-size: 14pt;
  vertical-align: text-bottom;
  
}

.titleInfo{
  display: inline-block;
  transition: color 0.2s;
}

.titleInfo:hover{
  cursor: pointer;
  color: $button;
}

.inputEditName{
  display: none;
  padding:0;
}

#mediaNameInput{
  padding:0;
  margin:0px 50px 0px 5px;
}

.video-block{
  display: flex;
  width: auto;
  margin: 15px;
}

.name{
  width: 100%;
  background-color: $primary-dark;
  color: white;
}

.infos{
  float: right;
}

.infos:hover{
  cursor: pointer;
  color: $button;
}

.video-player{
  width: 100%;
  max-height: 800px;
  background-color: black;
}

video{
  width: 100%;
  height: 100%;
}

.medias{
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.media{
  background-color: $background;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  font-weight: 700;
  border-radius: 10px;
}

.media:hover{
  background-color: $background-hover;
}

label{
  display: flex;
  flex-direction: row;
  align-items: center;
}

label:hover{
  color: darkred;
  cursor: pointer;
}

.material-icons{
  font-size: 14pt;
}

.media-group::-webkit-scrollbar-track{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.media-group::-webkit-scrollbar{
  width: 6px;
  background-color: #F5F5F5;
}

.media-group::-webkit-scrollbar-thumb{
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: $primary-dark;
}

#video-div{
  position: relative;
  width:100%;
  height:calc(100% - 30px);
  overflow: hidden;
}

#displayOption{
  margin-left:auto;
  margin-bottom:0;
  opacity:0;
  transition: opacity 0.5s, background-color 0.5s, border 0.2s, box-shadow 0.5s, transform 0.3s;;
}

@media all and(max-width: 720px){
  .title{
    color: $primary-dark;
    background-color: white;
    width: auto;
    padding: 5px 5px 5px 10px;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-top: 10px;
    text-align: center;
  }
  #video-div{
    position: relative;
    width: 100vw;
    height: 80%;
    overflow: hidden;
  }
}
</style>