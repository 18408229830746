<template>
    <div>
        <videotek-admin-menu></videotek-admin-menu>
        <div class="admin-panel">
            <videotek-table-users  @action="action"></videotek-table-users>
            <videotek-table-transactions v-if="walletId" :walletId="walletId"></videotek-table-transactions>
            <videotek-table-invoices v-if="user" :userId="user.id"></videotek-table-invoices>
            <videotek-admin-user v-if="user" :user="user"></videotek-admin-user>
        </div>
    </div>
</template>

<script>

import VideotekMenu from '../videotek-menu.vue';
import VideotekAdminMenu from './videotek-admin-menu.vue';
import VideotekTableTransactions from './videotek-table-transactions.vue';
import VideotekTableInvoices from './videotek-table-invoices.vue';
import VideotekTableUsers from './videotek-table-users.vue';
import VideotekAdminUser from './videotek-admin-user.vue';
import VideotekProfil from '../videotek-profil.vue';

export default {
    name: 'VideotekAdmin',
    components: { VideotekAdminMenu, VideotekMenu, VideotekTableTransactions, VideotekTableUsers, VideotekAdminUser, VideotekProfil, VideotekTableInvoices },
    data(){
        return {
            walletId: undefined,
            user: undefined
        }
    },
    methods:{
        action(data){
            this.walletId = data.wallet.id;
            this.user = data;
        }
    }
}

</script>

<style scoped lang="scss">
.admin-panel{
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}
</style>