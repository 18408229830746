const httpUtils = require('./http-utils.js');

function getTransactions(walletId, limit, offset) {
    var url = '/transactions';
    if (limit || offset) {
        url += `?filter[skip]=${offset}&filter[limit]=${limit}&filter[where][walletId]=${walletId}`;
    }
    return httpUtils._get(url);
}

function getInvoices(userId, limit, offset) {
    var url = '/invoices';
    if (limit || offset) {
        url += `?filter[skip]=${offset}&filter[limit]=${limit}&filter[where][customerId]=${userId}`;
    }
    return httpUtils._get(url);
}

module.exports = { getTransactions, getInvoices }