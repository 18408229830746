<template>
    <div v-if="texts" class="register-password">
        <img
          id="login-logo"
          src="../../assets/logo_videotek.svg"
          alt="Videotek by Weaverize"
        />
        <h2> {{ this.$i18n.translate(texts, "confirmPassword") }}</h2>
        <p>{{ this.$i18n.translate(texts, "passwordTips") }}</p>
        <form @submit.prevent="confirm">
            <videotek-input :placeholderText="this.$i18n.translate(texts, 'password')" oncopy="return false" type="password" v-model="password" required></videotek-input>
            <videotek-input :placeholderText="this.$i18n.translate(texts, 'confirm')" onpaste="return false" type="password" v-model="confirmedPassword" required></videotek-input>
            <input id="confirm" type="submit" value="confirm" hidden />
            <p v-if="!same" class="error">{{ this.$i18n.translate(texts, 'same') }}</p>
            <p v-else-if="tooShort" class="error">{{ this.$i18n.translate(texts, 'length') }}</p>
            <videotek-button v-else :text="this.$i18n.translate(texts, 'submit')" for="confirm" :submitForm="true"></videotek-button>
        </form>
        <videotek-lang></videotek-lang>
    </div>
</template>

<script>
import VideotekInput from "../generics/videotek-input.vue"
import VideotekButton from "../generics/videotek-button.vue"
import VideotekLang from "../generics/videotek-lang.vue";

export default{
    components: { VideotekInput, VideotekButton, VideotekLang },
    name:'VideotekResetPassword',
    data(){
        return {
            password: '',
            confirmedPassword: '',
            token: undefined,
            texts: undefined
        }
    },
    methods:{
        confirm(){
            this.$users.setPassword(this.token, this.password).then(()=>{              
              sessionStorage.clear();
              this.$utils.success(this.$i18n.translate(texts, 'success'), this.$i18n.translate(texts, 'successResume'));
              this.$router.push("/");              
            });
        },
    },
    computed:{
      same: function(){
        return this.confirmedPassword == this.password;
      },
      tooShort: function(){
        return this.password.length < 8;
      }
    },
    mounted(){
        this.texts = require("./videotek-reset-password.json");
        if(this.$route.params){
            this.token = this.$route.params.token;
            sessionStorage.setItem('user_token', this.token);
        }else{
            this.$router.push("/");
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/style.scss";

.register-password {
  background-color: $background;
  color: $text;
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  max-width: 500px;
  border-radius: 20px;
  font-family: $font-family;
  position:relative;

  .error{
    color: red;
  }
   #login-logo {
        max-width: 60%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

  form {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
 
  input {
    margin-bottom: 20px;
    font-family: $font-family;
    width: 100%;
  }

  .confirm-button {
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    background: $primary;
    margin-bottom: 20px;
    color: white;
    border-radius: 10px;
    border: solid 2px $primary;
  }

  label:hover {
    cursor: pointer;
  }

  label:active {
    background-color: white;
    color: $primary-dark;
  }

  h2{
    color: $primary-dark;
    width: 100%;
    text-align: center;
  }

  p{
    margin:0px 20px;
    text-align: center;
        font-size:small;
    }
}
@media all and (max-width: 720px) {
  .register-password{
    width: 100%;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    max-width: initial;
    height: 100vh;
  }
}
</style>
