<template>
    <div v-if="texts" class="reset-password-demand">
        <videotek-button class="button-back" icon text="arrow_back" @click="backLogin"></videotek-button>
        <img
        id="login-logo"
        src="../../assets/logo_videotek.svg"
        alt="Videotek by Weaverize"
        />
        <h2> {{ this.$i18n.translate(texts, 'resetPassword') }} </h2>
        <p v-if="!emailSent"> {{ this.$i18n.translate(texts, 'emailPlease') }}</p>
        <form v-if="!emailSent" @submit.prevent="reset">
            <videotek-input :placeholderText="this.$i18n.translate(texts, 'emailAddress')" required="required" type="email" v-model="email"></videotek-input>
            <input id="reset" type="submit" value="reset" hidden />
            <videotek-button for=reset :text="this.$i18n.translate(texts, 'submit')" :submitForm="true"></videotek-button>
        </form>
        <p v-if="emailSent">
            {{ this.$i18n.translate(texts, 'emailSent') }}
        </p>
        <videotek-lang></videotek-lang>
    </div>
</template>

<script>
import VideotekInput from "../generics/videotek-input.vue"
import VideotekButton from "../generics/videotek-button.vue"
import VideotekLang from "../generics/videotek-lang.vue";

export default{
    components: { VideotekInput, VideotekButton, VideotekLang },
    name:'VideotekDemandPassword',
    data(){
        return {
            email: undefined,
            emailSent: false,
            texts:undefined
        }
    },
    methods:{
        reset(){
            this.$users.resetPassword(this.email).then(() => {
                this.emailSent = true;
            }).catch(error => {
                console.error(error);
                this.emailSent = false;
            });
        },
        backLogin(){
            this.$router.push("/");
        }
    },
    mounted(){
        this.texts = require('./videotek-demand-password.json');
    }
}
</script>

<style scoped lang="scss" >
@import "../../assets/style.scss";

.reset-password-demand{
    background-color: $background;
    color: $text;
    margin: 100px auto 0 auto;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    max-width: 500px;
    border-radius: 20px;
    font-family: $font-family;
    position: relative;
    padding-bottom: 20px;
}

#login-logo {
    max-width: 60%;
    margin-top: 40px;
    margin-bottom: 40px;
}

form {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

input {
    margin-bottom: 20px;
    font-family: $font-family;
    width: 100%;
}

.reset-button {
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    background: $primary;
    margin-bottom: 20px;
    color: white;
    border-radius: 10px;
    border: solid 2px $primary;
}

label:hover {
    cursor: pointer;
}

label:active {
    background-color: white;
    color: $primary-dark;
}

h2{
    color: $primary-dark;
    width: 100%;
    text-align: center;
}

p{
    text-align: center;
    font-size:small;
}

.button-back{
  position: absolute;
  left:0;
}

@media all and (max-width: 720px) {
  .reset-password-demand{
    width: 100%;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    max-width: initial;
    height: 100vh;
  }
}
</style>
