<template>
<div>
    <videotek-menu></videotek-menu>
    <div v-if="texts" class="profil">
    <div class="param-header">
      <videotek-button iconNoAnim text="arrow_back" @click="backMain"></videotek-button>
    <div class="title"> {{ this.$i18n.translate(texts, 'shop') }} </div>
    </div>
      <videotek-video-player></videotek-video-player>
      <div class="parameters">
        <div class="param">    
          <div v-if="user && user.wallet" class="solde">
            {{ this.$i18n.translate(texts, 'myCredits', [user.wallet.credits]) }}
          </div>
        </div>
        <hr>
        <div v-if="credits" class="param">
          <div class="buy"> 
            {{ this.$i18n.translate(texts, 'buyCredits') }}
            <form>
              <div v-for="(credit, index) in credits" :key="index" class="credit">
                <label class="credit-block">
                  <input :for="credit.priceHT" type="radio" name="credits" :id="credit.id" :value="credit.priceHT" @change="selectCredit(credit)">
                  <div class="amount">{{ credit.amount }} {{ this.$i18n.translate(texts, 'credits') }}</div>
                  <div class="priceHT">{{ readPrice(credit.priceHT) }} € </div>
                </label>
              </div> 
            </form>
            <div class="infos">{{ this.$i18n.translate(texts, 'infos') }} </div>
          </div>
        </div>
        <hr>
      </div>
      <transition name="fade">
      <form v-if="stripeApi && purchase" id="payment-form">
        <div id="card-element"><!-- stripe iframe injection --></div>
        <videotek-button id="payment-button" :text="this.$i18n.translate(texts, 'purchase')" :submitForm="true"></videotek-button>
        <pulse-loader id="loader" :loading="spinning" color="#4885ad" size="50px"></pulse-loader>
      </form>
      </transition>
      <videotek-modal :config="modalConfig" @execute="execute"></videotek-modal>
  </div>
  </div>
</template>

<script>
import VideotekMenu from "./videotek-menu.vue";
import VideotekButton from "./generics/videotek-button.vue";
import VideotekInput from "./generics/videotek-input.vue";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import VideotekModal from "./generics/videotek-modal.vue";
import stripeKey from '../properties/stripe-keys.json';

export default {
  name: 'Payment',
  components: { VideotekMenu, VideotekButton, PulseLoader, VideotekModal, VideotekInput },
  data() {
    return {
      stripeApi : undefined,
      purchase: false,
      user: undefined,
      credits: undefined,
      selectedCredit: undefined,
      spinning: false,
      modalConfig: {},
      submit: undefined,
      texts: require('./videotek-payment.json')
    }
  },
  mounted() {
    this.texts = require('./videotek-payment.json');
    this.$users.me().then(data =>{
      this.user = data.user;
      if(!this.user.complete){
        this.$router.push('/profil');
      }else{
        this.$payments.getWallet(this.user.id).then(wallet=>{
          this.user.wallet = wallet;  
        });
      }
    });

    this.$payments.getBuyableCredits().then(data => {
      this.credits = data;    
    });

    if (!document.getElementById('stripe-script')) { 
      var src = 'https://js.stripe.com/v3/';
      var script = document.createElement('script');
      
      script.setAttribute('src', src);
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('id', 'stripe-script');
      document.head.appendChild(script);

      script.onload = () => {
        this.stripeApi = Stripe(stripeKey.stripe.public_key);
      }
    }
  },
  beforeRouteLeave() {
    var script = document.getElementById('stripe-script');
    if (script) { 
      script.remove() 
    }
  }, 
  methods: {
    purchaseCredits() {
      this.purchase = true;
      setTimeout(()=>{
        this.$payments.createPayment({userId: this.user.id, credit: this.selectedCredit.id}, (err, response) => {
          this.$payments.createStripeForm(this.stripeApi, response.clientSecret, (onSubmit) => {   
            this.submit = onSubmit;
          }, () => {
            this.confirmModal();
          });
        });
      }, 500);
    },
    confirmModal() {
      var self = this;
      this.modalConfig = {
        show: true,
        loader: false,
        loading: false,
        validate: 'confirmPayment',
        close: 'close',
        name: 'confirmPayment',
        text: self.$i18n.translate(self.texts, 'confirmPayment', [self.selectedCredit.amount, self.selectedCredit.priceTTC, self.selectedCredit.priceHT]),
        canceled: true
      }
    },
    execute(action){
      this[action]();
    },
    confirmPayment() {
      var self = this;
      if(this.submit) {

        this.modalConfig = {
          name: 'confirmPayment',
          text: self.$i18n.translate(self.texts, 'paymentProgress'),
          show: true,
          loader: true,
          loading: true,
        };

        this.submit.func(this.submit.args, ()=>{
          this.modalConfig = {
            name: 'confirmPayment',
            show: false
          };
          setTimeout(()=>{
            this.backMain();
          }, 2000);
        });
      }
    },
    close(){
      window.location.reload();
    },
    selectCredit(credit){
      if(this.spinning){
        return;
      }
      if(this.purchase){
        this.$router.go();
      }else{
        this.selectedCredit = credit;
        this.purchaseCredits();
      }
    },
    backMain() {
      this.$router.push("/main");
    },
    readPrice(price){
      return (!price.toString().replaceAll(".",",").includes(',')) ? `${price},00` : price.toString().replace(".",",");
    }
  },
};
</script>

<style lang="scss" scoped>

@import "../assets/style.scss";

#loader{
  margin:auto;
}

.profil {
  font-family: $font-family;
  font-size:large;
  max-width: 600px;
  width: 60%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: $background-dark;
  margin: 60px auto 0 auto;
  transition: box-shadow 0.5s;
}
.solde{
  font-size: medium;
  margin-bottom: 10px;
}

.credit-block{
  border-radius: 6px;
  box-shadow: 0px 2px 5px 0px rgb(202, 216, 219);
  transition: box-shadow 0.5s;
}

.credit-block:hover{
         box-shadow: 0px 2px 10px 2px rgb(165, 170, 172);
}

.credit{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 4px;
  width: 30%;
  max-width: 80px;
}

.amount{
  background-color: $primary-dark;
  color:white;
  font-size: 18px;
  padding: 5px;
  
}

.priceTTC{
  background-color: $background-dark;
  font-size: 20px;
  padding: 2px 0;
}

.priceHT{
  background-color: $background-dark;
  font-size: 20px;
  padding: 2px 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

label{
  background-color: $primary-dark;
}

.param-header{
  background-color: $primary-dark;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}


#arrow-back {
  width: fit-content;
  border-radius: 100%;
  color: white;
  padding: 2px;
  border: solid 2px #1a252973;
  font-size:x-large;
  margin: 0px;
}

#arrow-back:hover {
  background-color: $text;
  border-color: $primary;
  cursor: pointer;
}

.title{
  color:white;
  font-size: large;
  margin-right: auto;
  margin-left: auto;
  padding-right:32px;
}

.parameters{
  background-color: $background;
  display: flex;
  border-radius: 10px;
  margin: 20px;
  flex-direction: column;
}

.param{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: medium;
  width: 100%;
}
 
.buy{    
  width: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

form{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 8px
}

.material-icons {
  color: $primary;
  margin: 10px;
}
hr{
  margin:0;
}
hr:last-child{
  display: none;
}
  
.payment-button{
  width:fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin-bottom:20px;
  margin-left: auto;
  margin-right: auto;
}

#payment-button{
  margin:auto;
}

#payment-form{
  display: flex;
  flex-direction: column;
}

.profil:hover{
  box-shadow: 0px 2px 10px 2px rgb(202, 216, 219);
}

#card-element{
  border-radius: 5px;
  margin-left: 25px;
  margin-right:25px;
  padding: 5px;
  box-shadow: 0px 1px 2px 1px rgb(202, 216, 219);
  transition: box-shadow 0.5s;
}
#card-element:hover{
  box-shadow: 0px 2px 8px 1px rgb(170, 177, 179);
}

.fade-enter-active{
  opacity: 0;
}

.fade-enter-to{
  opacity: 1;
  transition: opacity 2s;
}

#cardOwner{
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  color: #32325d;
  font-family: 'Roboto';
  font-weight: bolder;
  transition: background-color 0.3s, border-radius 0.2s, border 0.3s, box-shadow 0.3s;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px white;
  box-shadow:  -1px 2px 5px 0px #bdc9c6;
}

#cardOwner:hover{
    outline: none;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: -1px 3px 9px 2px #bdc9c6;
}
#cardOwner:focus{
    outline: none;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
}
#cardOwner::placeholder{
    color: #33335e9e;
    transition:color 0.2s, visibility 0.2s;
}

.infos{
  text-align: left;
  margin: 16px  0 0 8px;
  font-size: small;
  color: red;
}

@media all and(max-width: 720px){
  .profil{
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    margin: 0;
    transition: box-shadow 0.5s;
    height: 100vh; 
  } 
  .param-header{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: white;
  }
  .title{
    color: $text;
  }
}
</style>