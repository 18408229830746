<template>
    <div class="table-transactions">
        <h2 class="title">Transactions du wallet {{_walletId}} </h2>
        <videotek-table v-if="transactions && component" :child="component" :dataset="transactions" :pages="pages" :limit="limit" :offset="offset" @update="update" @paginate="paginate"></videotek-table>
    </div>
</template>

<script>
import VideotekTable from '../generics/videotek-table.vue';
import VideotekTransaction from './videotek-transaction.vue';


export default {
    name: 'VideotekTableTransactions',
    components: { VideotekTable, VideotekTransaction },
    props:{
        walletId: String
    },
    data(){
        return {      
            transactions: [],
            component: VideotekTransaction,
            pages: [10,20,50],
            limit: 10,
            offset: 0,
            _walletId: undefined
        }
    },
    watch:{
        walletId:function(old, newValue){
            this._walletId = this.walletId;
            this.getTransactions();
        }
    },
    methods:{
        getTransactions(){
            this.$admins.getTransactions(this._walletId, this.limit, this.offset).then(data => {
                this.transactions = data;
            }).catch(error => {
                console.log(error);
            });
        },
        update(limit, offset){
            this.offset = offset;
            this.limit = limit;
            this.getTransactions();
            
        },
        paginate(limit){
            this.limit = limit;
            this.getTransactions();
        }
    },
    mounted(){
        this._walletId = this.walletId;
        this.getTransactions();        
    }
}
</script>

<style scoped lang="scss">

@import "../../assets/style.scss";

.table-transactions{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    background-color: white;
    transition: box-shadow 0.5s;
    border-radius: 5px;
    margin-top: 12px;
}

.table-transactions:hover{
  box-shadow: 0px 2px 10px 2px rgb(202, 216, 219);
}

.title{
    margin-bottom: 0;
    color: $text;
    font-size: medium;
    margin-left: 8px;
}
</style>