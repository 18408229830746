import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import VideotekLogin from "./components/login/videotek-login.vue";
import VideotekMain from "./components/videotek-main.vue";
import VideotekProfil from "./components/videotek-profil.vue";
import VideotekPayment from "./components/videotek-payment.vue";
import VideotekDownload from "./components/videotek-download.vue";
import VideotekRegister from "./components/login/videotek-register.vue";
import VideotekResetPassword from "./components/login/videotek-reset-password.vue";
import VideotekDemandPassword from "./components/login/videotek-demand-password.vue";
import VideotekEditor from "./components/videotek-editor.vue";
import VideotekAdmin from "./components/administration/videotek-admin.vue";
import VideotekLost from "./components/videotek-lost.vue";

import VTooltip from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";
import { login, logout } from "./services/auth-service.js";

const http = require("./services/http-utils.js");
const collections = require("./services/collection-service.js");
const medias = require("./services/media-service.js");
const payments = require("./services/payment-service.js");
const users = require("./services/user-service.js");
const utils = require("./services/utils.js");
const editings = require("./services/editings-service.js");
const admins = require("./services/admin-services.js");
const i18n = require("./services/i18n.js");
const address = require("./services/address-services.js");

const routes = [
  {
    path: "/",
    component: VideotekLogin,
    beforeEnter: (to, from, next) => {
      if (sessionStorage.getItem("user_token")) {
        users.me().then(() => {
          next("/main");
        }).catch(error => {
          next();
        });
      } else {
        next();
      }
    }
  },
  {
    path: "/main",
    component: VideotekMain,
    beforeEnter: (to, from, next) => {
      adminRedirect().then(access => {
        if (access)
          next('/admin');
        else
          redirectToMain(next);
      }).catch(error => {
        next('/');
      });
    }
  },
  {
    path: "/profil",
    component: VideotekProfil,
    beforeEnter: (to, from, next) => {
      redirectToMain(next);
    }
  },
  {
    path: "/payment",
    component: VideotekPayment,
    beforeEnter: (to, from, next) => {
      redirectToMain(next);
    }
  },
  {
    path: "/download",
    component: VideotekDownload,
    beforeEnter: (to, from, next) => {
      redirectToMain(next);
    }
  },
  {
    path: "/edit",
    component: VideotekEditor,
    beforeEnter: (to, from, next) => {
      redirectToMain(next);
    }
  },
  {
    path: "/register",
    component: VideotekRegister
  },
  {
    path: "/setpassword/:token",
    component: VideotekResetPassword
  },
  {
    path: "/reset",
    component: VideotekDemandPassword
  },
  {
    path: "/admin",
    component: VideotekAdmin,
    beforeEnter: (from, to, next) => {
      if (!sessionStorage.getItem('user_token')) {
        next('/');
      } else {
        adminRedirect().then(access => {
          if (access) {
            next();
          }
          else
            next('/lost');
        }).catch(error => {
          next('/');
        });
      }
    }
  },
  {
    path: "/:pathMatch(.*)*",
    component: VideotekLost
  }
];

function adminRedirect() {
  return new Promise((resolve, reject) => {
    users.admin().then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

function redirectToMain(next) {
  if (!sessionStorage.getItem("user_token")) {
    next("/");
  } else {
    next();
  }
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const app = createApp(App);

//global utilities
app.config.globalProperties.$login = login;
app.config.globalProperties.$logout = logout;
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$http = http;
app.config.globalProperties.$api = http.BASE_URL;

//services definitions
app.config.globalProperties.$users = users;
app.config.globalProperties.$collections = collections;
app.config.globalProperties.$medias = medias;
app.config.globalProperties.$payments = payments;
app.config.globalProperties.$editings = editings;
app.config.globalProperties.$admins = admins;
app.config.globalProperties.$i18n = i18n;
app.config.globalProperties.$address = address;

app.use(router).mount("#app");
app.use(VTooltip, {
  classes: "bg-neutral-700 opacity-100 rounded-md text-white"
});
// app.config.warnHandler = function (msg, vm, trace) {
//   return null
// }