<template>
  <div v-if="texts && collection" class="media-groups">
    <div class="title"> {{ this.$i18n.translate(texts, 'myCollection') }}</div>
    <div v-if="nothingToDo" class="groups-container-first">{{ this.$i18n.translate(texts, 'addVideo') }}
      <videotek-upload id="upload-form-first"
          :collection="collection"
          @upload="upload"
          @uploaded="uploaded"
          @uploading="uploading"
        ></videotek-upload></div>
    <div v-else class="blockgroup">
      <videotek-input :placeholderText="this.$i18n.translate(texts, 'searchVideo')" v-model="searchText" @input="search"></videotek-input>
      <div class="groups-container">
        <div class="group-container-processing" v-for="(file, index) in uploadingFiles" :key="index">
          <div class="group-infos-processing">
            <div class="group-processing">
              {{ file.file.name }} {{ file.progress }} %
            </div>
            <beat-loader color="white" size="16px"></beat-loader>
          </div>
        </div>
        <div class="group-container-processing" v-for="(edit, index) in editingsInProgress" :key="index">
          <div class="group-infos-processing">
            <div class="group-processing">
              {{ this.$i18n.translate(texts, 'editingProgress', [getMediaGroupById(edit.groupId).name, getStatus(edit)]) }} {{ edit.progress }} %
            </div>
            <beat-loader color="white" size="16px"></beat-loader>
          </div>
        </div>
        <div class="group-container-processing" v-for="(media, index) in processingMedias" :key="index">
          <div v-if="media" class="group-infos-processing">
            <div class="group-processing">
              {{ this.$i18n.translate(texts, 'processingMedias', [media.name]) }}
            </div>
            <beat-loader color="white" size="16px"></beat-loader>
          </div>
        </div>
        <div
          @click="selectGroup(mediaGroup)"
          class="group-container"
          v-for="(mediaGroup, index) in collection.mediaGroups"
          :key="index"
        >
          <div class="group-infos" v-if="mediaGroup.show && mediaGroup.duration">
            <div v-if="mediaGroup.cover" class="group-thumbnail">
              <img class="thumbnail" :src="mediaGroup.cover">
            </div>
            <div class="group-details">
              <div class="group-name">
                {{ mediaGroup.name }}
              </div>
              <div class="group-option-list">
                <div v-for="(media, index) in mediaGroup.medias" :key="index" class="group-option">{{ media.version }}</div>
              </div>
              <div class="group-date"> {{ this.$i18n.translate(texts, 'duration', [this.$utils.getDuration(mediaGroup.duration.toFixed(2))]) }}</div>
            </div>    
          </div>
        </div> 
      </div> 
      <videotek-upload
            :collection="collection"
            @upload="upload"
            @uploaded="uploaded"
            @uploading="uploading"
          ></videotek-upload>
    </div>
  </div>
</template>

<script>
import VideotekUpload from "./videotek-upload.vue";
import VideotekButton from "./generics/videotek-button.vue";
import VideotekInput from "./generics/videotek-input.vue";
import VideotekProgress from "./generics/videotek-progress.vue";
import VTooltip from "v-tooltip"
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'

export default {
  components: { VideotekUpload, VideotekButton, VideotekInput, VideotekProgress, VTooltip, BeatLoader },
  name: "MediaGroups",
  emits: ["mediaGroupChange", "refresh"],
  props: {
    collection: Object
  },
  data() {
    return {
      uploadingFiles: [],
      searchText: null,
      thumbnailsCached:[],
      editingsInProgress:[],
      processingMedias: [],
      eventSourceEditings: undefined,
      eventSourceMedias: undefined,
      texts: require('./videotek-media-groups.json')
    };
  },
  mounted() {
    this.texts = require('./videotek-media-groups.json');
    this.editingsInProgress = [];
    this.uploadingFiles = [];
    this.processingMedias = [];
    this.getCovers();
    this.initScheduleEditings();
    this.isThereUploads();
    this.setBeforeLeaveEvent();
  },
  computed:{
    nothingToDo: function() {
      return this.uploadingFiles.length == 0 && this.processingMedias.length == 0 && this.collection.mediaGroups.length == 0;
    }
  },
  watch:{
    collection: function(oldVal, newVal) {
      this.editingsInProgress = [];
      this.processingMedias = [];
      this.getCovers();
      this.isThereUploads();
      this.initScheduleEditings();
      this.setBeforeLeaveEvent();
    }
  },
  methods: {
    getCovers() {
      this.collection.mediaGroups.forEach(mediaGroup => {
        this.$medias.getGroup(mediaGroup.id).then(group => {
          mediaGroup.cover = this.$medias.getCover(group.medias[0].id)
        });
      });
    },
    selectGroup(mediaGroup) {
        this.$medias.getGroup(mediaGroup.id).then(data => {
           this.$emit("mediaGroupChange", data);
        });
    },
    uploaded(id) {
      this.uploadingFiles = this.uploadingFiles.filter((file) => file.id != id);
      this.isThereUploads();
    },
    uploading(id, progress) {
      this.uploadingFiles.filter((file) => file.id == id)[0].progress =
        progress;
    },
    upload(file) {
      this.uploadingFiles.push(file);
    },
    search(){
      this.collection.mediaGroups.forEach(group => group.show = group.name.toUpperCase().includes(this.searchText.toUpperCase())); 
    },
    initScheduleEditings(){
      this.scheduleEditings();
    },
    scheduleEditings() {
      this.$editings.getEditingsInProgress().then(editings => {
        if(editings && editings.length > 0) {
          editings.forEach(editing => this.updateEditings(editing));
          this.$editings.getChangeStream((data)=>{
            this.updateEditings(data);
          },(source)=>{
            if(!this.eventSourceEditings){
              this.eventSourceEditings = source;
            }
          }, ()=> {
            this.closeEditingStream();
            this.getMediaChangeStream(this.collection.id);
          },);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    updateEditings(editing){
      this.editingsInProgress = this.editingsInProgress.filter(element => element.id != editing.id);
      this.editingsInProgress.push(editing);
    },
    isThereUploads() {
      this.$collections.isThereUploads(this.collection.id).then(medias => {
        if(medias && medias.length > 0) {
          medias.forEach(media => this.updateProcessingMedias(media));
          this.getMediaChangeStream(this.collection.id);
        }
      }).catch(error => {
        console.log(error);
      })
    },
    getMediaChangeStream(collectionId) {
      this.$medias.getChangeStream(collectionId, (media) => { 
        this.updateProcessingMedias(media);
      }, (source)=>{
        this.eventSourceMedias = source;
      }, () => {
        this.$utils.success(this.$i18n.translate(this.texts, 'successUpload'), this.$i18n.translate(this.texts, 'successUploadResume'));
        this.closeMediaStream();
        this.$emit('refresh');
      });
    },
    updateProcessingMedias(media){
      this.processingMedias = this.processingMedias.filter(element => element.id != media.id); 
      this.processingMedias.push(media);    
    },
    getStatus(editing){
      return this.$i18n.translate(this.texts, editing.status.toLowerCase());
    },
    getMediaGroupById(id){
      return this.collection.mediaGroups.filter(group => group.id == id)[0];
    },
    closeMediaStream(){
      if(this.eventSourceMedias){
        this.eventSourceMedias.close();
      }
    },
    closeEditingStream(){
      if(this.eventSourceEditings){
        this.eventSourceEditings.close();
      }
    },
    beforeUnloadListener(event){
      if(this.uploadingFiles.length > 0){
        event.preventDefault();
        return event.returnValue = "progress";
      }else{
        return "";
      }
    },
    setBeforeLeaveEvent(){
      window.addEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
    },
    removeBeforeLeaveEvent(){
      window.removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
    }
  },
  beforeUnmount(){
    this.removeBeforeLeaveEvent();
    this.closeMediaStream();
    this.closeEditingStream();
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/style.scss";

.media-groups {
  display: flex;
  flex-direction: column;
  flex:2;
  max-width: 400px;
  margin-left: 20px;
  background-color: $background-dark;
  font-family: $font-family;
  border-radius: 5px;  
}

.blockgroup{
  display: flex;
  flex-direction: column;
  width: auto;
  height:calc(100% - 30px);
}

.groups-container{
  width: auto;
  margin: 15px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 120px);
  padding-bottom: 10px;
  background: #ffffff;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.thumbnail{
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.group-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  transition: background-color 0.2s, color 0.2s;
  height: 120px;
  margin: 10px;
}

.group-container-processing{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  transition: background-color 0.2s, color 0.2s;
  margin: 10px;
}

.group-infos{
  display: flex;
  width: 100%;
  height: 100%;
}

.group-infos-processing{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: $button-hover;
  color: white;
  border-radius: 5px;
  padding: 4px;
}

.group-processing{
  margin: 10px;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

.spinner-progress{
  display: flex;
  font-size: small;
  color: darkgray;
  align-items: center;
}

.group-thumbnail{
    display: flex;
    width: 50%;
}

.group-details{
  display: flex;
  flex-direction: column;
  width: 50%;
}

.group-name{
  width: auto;
  margin: 5px 10px 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group-option-list{
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-left: 10px;
}

.group-option{
  font-size: small;
  margin-right: 10px;
  background: $detail;
  color: white;
  width: fit-content;
  margin-bottom: 6px;
  padding: 1px 10px 2px 10px;
  border-radius: 8px;
}

.title{
  width: auto;
  background-color: $primary-dark;
  color: white;
  padding: 5px 5px 5px 10px;
  font-weight: 600;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.section-title{
  width: auto;
  margin: 10px 15px 0px 15px;
  font-size: small;
  background: $background;
  padding: 5px 10px 5px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.text-title{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;  
}
  
#filter-title{
  justify-content: start;
}
      
.group-option-progress{
  font-size: small;
  color: white;
  background: #ffbd42;
  padding: 5px 10px;
  margin: 0px 5px 5px 0px;
  border-radius: 30px;
}

.group-date{
  font-size: small;
  margin: 10px;
}

canvas{
  width: 90%;
  height: 60%;
  max-width: 250px;
  max-height: 200px;
  min-height: 100px;
  object-fit: contain;
}

.group-container:hover{
  cursor: pointer;
  background-color: $button-hover;
  color: white;
  box-shadow: $button-hover 2px 2px 7px 1px;
}

.groups-container::-webkit-scrollbar-track{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.groups-container::-webkit-scrollbar{
  width: 6px;
  background-color: #F5F5F5;
}

.groups-container::-webkit-scrollbar-thumb{
  background-color: $primary-dark;
}

#upload-form-first{
  bottom: auto;
  right: auto;
  margin-left:0;
}

.groups-container-first{
  display: flex;
  flex-direction: column;
  height:calc(100% - 200px);
  justify-content: center;
  align-items: center;
  font-size: large;
}

.progress{
  height:15px;
}

@media all and (max-width: 720px){
  .groups-container{
    max-height: 400px;
  }
  .title{
    color: $primary-dark;
    background-color: white;
    text-align: center;
    margin-top: 10px;
  }
  canvas{
    min-height: 100px;
    object-fit: fill;
  }
}
</style>