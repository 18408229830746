<template>
<input  :type="typeInput" :placeholder="placeholderText" v-model="inputValue">
</template>

<script>
export default {
    name: "VideotekInput",
    props: {
        placeholderText : String,
        typeInput : String,
        inputValue: String
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
input{
    font-family: 'Roboto';
    font-weight: bolder;
    padding: 5px 10px;
    margin: 5px 15px;
    width: calc(100% - 52px);
    background-color: rgba(255, 255, 255, 0);
    border:solid 1px rgba(255, 255, 255, 0);
    transition: background-color 0.3s, border-bottom 0.2s, border-radius 0.2s, border 0.3s;
    border-bottom: solid 1px rgb(148, 171, 192);
}
input:hover{
    outline: none;
    background-color: rgba(255, 255, 255, 0.8);
    border: solid 1px rgb(148, 171, 192);
    border-radius: 5px;
    box-shadow: 0px 1px 10px -5px ;
}
input:focus{
    outline: none;
    background-color: rgba(255, 255, 255, 0.8);
    border: solid 1px rgb(148, 171, 192);
    border-radius: 5px;
}
input::placeholder{
    color: $hover-grey;
    transition:color 0.2s, visibility 0.2s;
}
input:hover::placeholder{
    color: white;
    visibility: hidden;
}
[type="checkbox"]:not(:checked), 
[type="checkbox"]:checked {
	box-shadow: none;
    border:none;
}
</style>