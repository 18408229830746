var httpUtils = require('./http-utils.js');

function login(credentials) {
    return new Promise((resolve, reject) => {
        httpUtils._post('/users/login', credentials).then(data => {
            if (data && data.id) {
                sessionStorage.setItem('user_token', data.id);
                resolve(data);
            } else {
                reject(new Error(`No access token for user ${credentials.email}`));
            }
        }).catch(error => {
            reject(error);
        });
    });
}

function logout() {

    return httpUtils._post('/users/logout', null);
}

module.exports = { login, logout }