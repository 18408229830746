<template>
    <div class="lost">
        <h1>404 - Page not found</h1>
        <p>This page doesn't exists ... <b>redirecting in {{ count }} </b></p>
    </div>
</template>

<script>

export default {
    name: 'VideotekLost',
    data(){
        return {
            count: 5
        }
    },
    mounted(){
        var i = setInterval(()=>{
            this.count --;
            if(this.count <= 0){
                clearInterval(i);
                this.$router.push("/");
            }
        }, 1000);
    }
};

</script>

<style lang="scss" scoped>
@import "../assets/style.scss";

.lost{
   
    color: $text;
    font-family: $font-family;
    margin-top: 5%;
}

h1{
    background-color: $main-background;
    text-align:center;
}
p{
    background-color: $main-background;
    text-align:center;
}
</style>