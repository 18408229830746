<template>
  <div>
  <videotek-menu></videotek-menu>
  <div v-if="texts && user" class="profil">
    <div class="param-header">
    <videotek-button iconNoAnim text="arrow_back" @click="backMain"></videotek-button>
    <div class="title">{{ this.$i18n.translate(texts, 'profil') }}</div>
    </div>
      <div class="parameters">
        <div class="flex-form">
          <div class="flex-form">
            <div class="material-icons">person</div>
            <label>{{ user.name }}</label> 
          </div>
          <div class="flex-form">
            <div class="material-icons">mail</div>
            <label>{{ user.email }}</label>    
          </div>
          <div class="flex-form">
            <div class="material-icons">savings</div>
            <label v-if="user.wallet"> {{ user.wallet.credits }} {{ this.$i18n.translate(texts, 'credits') }} </label>  
          </div>
        </div>  
        <div class="invoice-informations">
          <form  @submit.prevent="saveProfil" @input="check()">
            <p v-if="!this.user.complete">
              {{ this.$i18n.translate(texts, 'fillProfil') }}
            </p>
            <p class="infos" v-else>
              {{ this.$i18n.translate(texts, 'additionalInfos') }}
            </p>
            <!-- <div class="flex-form">
              <div class="society">Entreprise</div> <videotek-input class="fit" v-model="form.isSociety" :inputValue="form.isSociety" typeInput="checkbox"></videotek-input>
            </div> -->
            <div v-if="!form.isSociety" class="flex-form">
              <videotek-input v-bind:class="{ 'error': errors.includes('lastname') }" class="flex-one" :placeholderText="this.$i18n.translate(texts, 'lastname')" v-model="form.lastname" :inputValue="form.lastname"></videotek-input>
              <videotek-input v-bind:class="{ 'error': errors.includes('firstname') }" class="flex-one" :placeholderText="this.$i18n.translate(texts, 'firstname')" v-model="form.firstname" :inputValue="form.firstname"></videotek-input>
            </div>
            <div v-if="form.isSociety" class="flex-form">
              <videotek-input v-bind:class="{ 'error': errors.includes('company') }" class="flex-one" :placeholderText="this.$i18n.translate(texts, 'company')" v-model="form.company" :inputValue="form.company"></videotek-input>
              <videotek-input v-bind:class="{ 'error': errors.includes('siren') }" class="flex-one" :placeholderText="this.$i18n.translate(texts, 'siren')" v-model="form.siren" :inputValue="form.siren"></videotek-input>
            </div>
            <videotek-search-address id="searchAddress" @selected="setAddress"></videotek-search-address>
            <videotek-input v-bind:class="{ 'error': errors.includes('address') }" :placeholderText="this.$i18n.translate(texts, 'address')" v-model="form.address" :inputValue="form.address"></videotek-input>
            <div class="flex-form">
              <videotek-input v-bind:class="{ 'error': errors.includes('city') }" class="flex-one" :placeholderText="this.$i18n.translate(texts, 'city')" v-model="form.city" :inputValue="form.city"></videotek-input>
              <videotek-input v-bind:class="{ 'error': errors.includes('cityCode') }" class="flex-one" type="number" :placeholderText="this.$i18n.translate(texts, 'cityCode')" v-model="form.cityCode" :inputValue="form.cityCode"></videotek-input>
              <videotek-input v-bind:class="{ 'error': errors.includes('cityCode') }" class="flex-one" type="text" :placeholderText="this.$i18n.translate(texts, 'country')" v-model="form.country" :inputValue="form.country"></videotek-input>
            </div>
            <input id="save" type="submit" hidden/>
            <videotek-button for="save" :text="this.$i18n.translate(texts, 'submit')" :submitForm="true"></videotek-button>
          </form>
        </div>
      </div>
  </div>
  </div>
</template>

<script>

import VideotekMenu from "./videotek-menu.vue";
import VideotekButton from "./generics/videotek-button.vue"
import VideotekInput from "./generics/videotek-input.vue";
import VideotekSearchAddress from "./generics/videotek-search-address.vue";

export default {
  name: "Profil",
  components: { VideotekMenu, VideotekButton, VideotekInput, VideotekSearchAddress },
  data() {
    return {
      user: undefined,
      form:{
        lastname:undefined,
        firstname:undefined,
        address:undefined,
        cityCode: undefined,
        city: undefined,
        country: undefined,
        isSociety: true,
        company: undefined,
        siren: undefined
      },
      errors:[],
      addresses:[],
      search:'',
      texts: require('./videotek-profil.json')
    };
  },
  mounted() {
    this.texts = require('./videotek-profil.json');
    this.$users.me().then(data => {
      this.user = data.user;
      this.form.lastname = this.user.lastname;
      this.form.firstname = this.user.firstname;
      this.form.address = this.user.address;
      this.form.cityCode = this.user.cityCode;
      this.form.city = this.user.city;
      this.form.country = this.user.country;
      this.form.company = this.user.company;
      this.form.siren = this.user.siren;
      this.form.isSociety = true;
      this.$payments.getWallet(this.user.id).then(wallet =>{
        this.user.wallet = wallet;   
      });
    });
  },
  methods: {
    check(){
      this.errors = [];
      Object.keys(this.form).forEach(key => {
        if(this.form.isSociety){
          if(!'lastname firstname isSociety'.includes(key)){
            if(!this.form[key] || this.form[key].toString().trim() == ''){
              this.errors.push(key);
            }
          }
        }else{
          if(!'siren company isSociety'.includes(key)){
            if(!this.form[key] || this.form[key].toString().trim() == ''){
              this.errors.push(key);
            }
          }
        }
        
      });
    },
    saveProfil(){
      this.check();
      if(this.errors.length == 0){
        
        var data = {}; 
        data.address = this.form.address;
        data.city = this.form.city;
        data.cityCode = this.form.cityCode;
        data.country = this.form.country;
        data.complete= true

        if(this.form.isSociety){
          data.isSociety = true;
          data.company = this.form.company;
          data.siren = this.form.siren;
          data.lastname = '';
          data.firstname = '';
        }else{
          data.isSociety = false
          data.lastname = this.form.lastname;
          data.firstname = this.form.firstname;
          data.company = '';
          data.siren = '';
        }

        this.$users.update(this.user.id, data).then(res => {
            this.$utils.success(this.$i18n.translate(this.texts, 'success'), this.$i18n.translate(this.texts, 'successResume'));
            setTimeout(()=>{
              this.$router.go();
            }, 2000);
          }).catch(error => {
            console.log(error);
          });
      }
    },
    backMain() {
      this.$router.push("/main");
    },
    setAddress(address){
      this.form.address = address.name;
      this.form.city = address.city;
      this.form.cityCode = address.postcode;
      this.form.country = 'France';
      this.check();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style.scss";

.profil {
  font-family: $font-family;
  font-size:large;
  max-width: 600px;
  width: 60%;
  display: flex;
  flex-direction: column;
  background-color: $background-dark;
  margin: 60px auto 0 auto;
  border-radius: 10px;
  transition: box-shadow 0.5s;
}

.param-header{
  background-color: $primary-dark;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#arrow-back {
  width: fit-content;
  border-radius: 100%;
  color: white;
  padding: 2px;
  border: solid 2px #1a252973;
  font-size:x-large;
  margin: 0px;
}

#arrow-back:hover {
  background-color: $text;
  border-color: $primary;
  cursor: pointer;
}

.title{
  color:white;
  font-size: large;
  margin-right: auto;
  margin-left: auto;
  padding-right:32px;
}

.parameters{
  display: flex;
  background-color: $background;
  border-radius: 10px;
  margin:20px;
  align-items: center;
  flex-direction: column;
}

.param{
  display: flex;
  align-items: center;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.material-icons{
  color: $primary-dark;
  margin: 10px;
}

hr{
  margin:0;
}

hr:last-child{
  display: none;
}

.profil:hover{
  box-shadow: 0px 2px 10px 2px rgb(202, 216, 219);
}

.invoice-informations{
  p{
    color: red;
    font-size: small;
  }
  input{
    font-size: 16px;
    font-weight: normal;
  }
}

.flex-form{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex-one{
  flex:1;
}

.error{
  color: red;
  border-radius: 5px;
  border: solid 1px;
}

.error::placeholder{
  color: red;
}

.error:hover{
  color: red;
}

.infos{
  color: $primary-dark !important;
  margin: 16px 0 0 15px;
}

.fit{
  width: fit-content;
}
.society{
  margin: 15px;
  font-size: medium;
}
@media all and(max-width: 720px){
  .profil{
    font-size: large;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    margin: 0;
    border-radius: 0;
    transition: box-shadow 0.5s;
    height: 100vh;
  }
  
  .param-header{
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .title{
    color: $text;
  }
}
</style>