<template>
    <div v-if="datas" class="transaction">
        <div class="transaction-infos">
            <div class="transaction-ids">
                <div class="transaction-id">
                    ID: {{datas.id}} 
                </div>
                <div class="transaction-date"> 
                    Date: {{datas.date}} 
                </div>
            </div>
            <div class="transaction-details">
                <div class="transaction-type">
                    Type: {{datas.type}}
                </div> 
                <div class="transaction-detail">
                    Détails: {{datas.details}}
                </div>
            </div>
        </div>
        <div class="transaction-infos">
            <div class="transaction-wallet">
                Wallet: {{datas.walletId}}
            </div>
            <div class="transaction-amounts">
                <div class="transaction-prices">
                    {{datas.price}} € | {{datas.amount}} Crédits
                </div>
                <div class="transaction-updates">
                    Avant: {{datas.before}} Après: {{datas.after}}
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
export default{
    name:'VideotekTransaction',
    props:{
        datas: Object
    },
    data(){
        return {}
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>

@import "../../assets/style.scss";

.transaction{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    height: fit-content;
    font-size: medium;
}

.transaction:nth-child(even) {background: rgb(238, 238, 238)}
.transaction:nth-child(odd) {background: #FFF}
.transaction:hover{
    cursor: pointer;
    background-color:rgb(208, 208, 208);
}
.transaction-infos{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 8px;
}

.transaction-amounts{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 8px;
}

.transaction-ids{
    display: flex;
    flex-direction: column;
}

.transaction-details{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.transaction-date{
    font-size: small;
    color: $hover-grey
}

.transaction-prices{
    margin-bottom: 8px;
}

.transaction-id{
    color: $primary-dark;
}

.transaction-type{
    margin-bottom: 8px;
}

.transaction-wallet{
    color: $primary-dark;
}
</style>