<template>
    <div class="table-users">
        <h2 class="title">Liste des utilisateurs</h2>
        <videotek-table v-if="users && component" :child="component" :dataset="users" :pages="pages" :limit="limit" :offset="offset" @update="update" @paginate="paginate" @action="action"></videotek-table>
    </div>
</template>

<script>
import VideotekTable from '../generics/videotek-table.vue';
import VideotekUser from './videotek-user.vue';

export default {
    name: 'VideotekTableUsers',
    components: { VideotekTable, VideotekUser },
    emits:['action'],
    data(){
        return {      
            users: [],
            component: VideotekUser,
            pages: [10,20,50],
            limit: 10,
            offset: 0,
        }
    },
    methods:{
        getUsers(){
            this.$users.getUsers(this.offset, this.limit).then(data => {
                this.users = data;
            }).catch(error => {
                console.log(error);
            });
        },
        update(limit, offset){
            this.offset = offset;
            this.limit = limit;
            this.getUsers();
            
        },
        paginate(limit){
            this.limit = limit;
            this.getUsers();
        },
        action(data){
            this.$emit('action', data);
        }
    },
    mounted(){
        this.getUsers();        
    }
}
</script>

<style scoped lang="scss">

@import "../../assets/style.scss";

.table-users{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    background-color: white;
    transition: box-shadow 0.5s;
    border-radius: 5px;
    margin-top: 12px;
}

.table-users:hover{
  box-shadow: 0px 2px 10px 2px rgb(202, 216, 219);
}

.title{
    margin-bottom: 0;
    color: $text;
    font-size: medium;
    margin-left: 8px;
}

</style>