<template>
<div>
    <input v-if="id" :list="id" :placeholder="this.$i18n.translate(texts, 'search')" v-model="value" @change="select" @keyup="searchAddress">
    <datalist :id="id">
        <option v-for="(address, index) in addresses" :key="index" :value="address.label"></option>
    </datalist>
</div>
</template>

<script>
export default {
    name: "VideotekSearchAddress",
    emits: ['selected'],
    props: {
        id: String,
        placeholderText : String,
    },
    data(){
        return {
            value: '',
            addresses: [],
            texts: require("./videotek-search-address.json")
        }
    },
    methods:{
        select(){
            this.addresses.forEach(address => {
                if(address.label == this.value){
                    this.$emit('selected', address);
                    this.value = "";
                }
            })
        },
        searchAddress(){
            if(this.value.length > 3){
                this.$address.search(this.value, (results) => {
                    this.addresses = results;    
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";

input{
    color: $primary-dark !important;
    font-size: 16px;
    font-weight: normal;
    font-family: 'Roboto';
    padding: 5px 10px;
    margin: 5px 15px;
    width: calc(100% - 52px);
    background-color: rgba(255, 255, 255, 0);
    border:solid 1px rgba(255, 255, 255, 0);
    transition: background-color 0.3s, border-bottom 0.2s, border-radius 0.2s, border 0.3s;
    border-bottom: solid 1px rgb(148, 171, 192);
}
datalist{
    font-size: 16px;
    font-weight: normal;
    font-family: 'Roboto';
    padding: 5px 10px;
    margin: 5px 15px;
    width: calc(100% - 52px);
    background-color: rgba(255, 255, 255, 0);
    border:solid 1px rgba(255, 255, 255, 0);
    transition: background-color 0.3s, border-bottom 0.2s, border-radius 0.2s, border 0.3s;
    border-bottom: solid 1px rgb(148, 171, 192);
}
datalist:focus{
    outline: none;
    background-color: rgba(255, 255, 255, 0.8);
    border: solid 1px rgb(148, 171, 192);
    border-radius: 5px;
}
input:hover{
    outline: none;
    background-color: rgba(255, 255, 255, 0.8);
    border: solid 1px rgb(148, 171, 192);
    border-radius: 5px;
    box-shadow: 0px 1px 10px -5px ;
}
input:focus{
    outline: none;
    background-color: rgba(255, 255, 255, 0.8);
    border: solid 1px rgb(148, 171, 192);
    border-radius: 5px;
}
input::placeholder{
    color: $primary-dark !important;
    transition:color 0.2s, visibility 0.2s;
}
input:hover::placeholder{
    color: white;
    visibility: hidden;
}
</style>