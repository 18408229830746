<template>
    <div v-if="collection" class="upload-form"> 
        <div class="input">
            <input id="file" name="file" type="file" accept="video/*" hidden @change="upload">
            <label for="file">
                <videotek-button icon text="add"></videotek-button>
            </label>
        </div>
    </div>
</template>

<script>
import VideotekButton from "./generics/videotek-button.vue"
export default {
        name:'VideotekUpload',
        components: { VideotekButton },
        props:{
            collection: Object
        },
        emits:['uploaded', 'uploading', 'upload'],
        data() {
            return {
                user:{}
            }
        },
        methods:{
            upload() {
                var file = document.getElementById("file").files[0];
                document.getElementById("file").value="";          
                this.$collections.uploadMedia(this.collection.id, file, (uploading)=>{
                    this.$emit('upload', uploading);
                },
                this.progress, this.success, this.error);       
            },
            progress(id, progress){
                this.$emit('uploading', id, progress);
            },
            error(msg){
                console.log('error while uploading file', msg);   
            },
            success(id){
                this.$emit("uploaded", id);
            }
        }
    }
</script>

<style lang="scss" scoped>

@import "../assets/style.scss";
.input{
    width: fit-content;
    float: right;
}
</style>