<template>
    <div v-if="texts" class="register-form">
      <videotek-button id="button-back" icon text="arrow_back" @click="backLogin"></videotek-button>
        <img
          id="login-logo"
          src="../../assets/logo_videotek.svg"
          alt="Videotek by Weaverize"
        />
        <h2 v-if="!emailSent"> {{ this.$i18n.translate(texts, "createAccount") }} </h2>
        <form v-if="!emailSent" @submit.prevent="register">
            <videotek-input :placeholderText="this.$i18n.translate(texts, 'username')" v-model="name" required></videotek-input>
            <videotek-input :placeholderText="this.$i18n.translate(texts, 'emailAddress')" type="email" v-model="email" required></videotek-input>
            <input id="register" type="submit" value="register" hidden />
            <videotek-button for=register :text="this.$i18n.translate(texts, 'register')" :submitForm="true"></videotek-button>
        </form>
        <div class="confirm" v-if="emailSent">
            <h2>{{ this.$i18n.translate(texts, 'emailSent') }}</h2>
            <p>
              {{ this.$i18n.translate(texts, 'emailResume') }}
            </p>
            <p>
              {{ this.$i18n.translate(texts, 'checkEmail') }}
            </p>
        </div>
        <videotek-lang></videotek-lang>
    </div>
</template>

<script>
import VideotekButton from "../generics/videotek-button.vue"
import VideotekInput from "../generics/videotek-input.vue"
import VideotekTooltip from "../generics/videotek-tooltip.vue"
import VideotekLang from "../generics/videotek-lang.vue";

export default {
  
    name: 'VideotekRegister',
    components: { VideotekButton, VideotekInput, VideotekTooltip, VideotekLang },
    data(){
        return {
            name: undefined,
            email: undefined,
            emailSent: false,
            texts: undefined
        }
    },
    methods:{
      register() {
        this.$users.register(this.name, this.email).then(()=>{
          this.emailSent = true;      
        }).catch(error => {
          console.error(error);
        });
      },
      backLogin(){
        this.$router.push("/");
      }
    },
    mounted(){
      this.texts = require('./videotek-register.json');
    }
}
</script>


<style scoped lang="scss">
@import "../../assets/style.scss";

.register-form {
  background-color: $background;
  position: relative;
  color: $text;
  margin: 100px auto 0 auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  max-width: 500px;
  border-radius: 20px;
  font-family: $font-family;

   #login-logo {
        max-width: 60%;
        margin-top: 40px;
        margin-bottom: 20px;
    }

  form {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
 
  input {
    margin-bottom: 20px;
    font-family: $font-family;
    width: 100%;
  }

  .register-button {
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    background: $primary;
    margin-bottom: 20px;
    color: white;
    border-radius: 10px;
    border: solid 2px $primary;
  }

  label:hover {
    cursor: pointer;
  }

  label:active {
    background-color: white;
    color: $primary-dark;
  }

  h2{
    color: $primary-dark;
    width: 100%;
    text-align: center;
  }
  p{
    font-size:small;
  }
  .confirm{
    text-align: center;
    width: 80%;
    h2{
      width: 100%;
    }
  }
}
#button-back{
  position: absolute;
  left:0;
}

@media all and (max-width: 720px) {
  .register-form{
    width: 100%;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    max-width: initial;
    height: 100vh;
  }
}
</style>