function translate(texts, key, params) {
    var lang = sessionStorage.getItem('lang');
    if (!lang) {
        lang = 'fr';
    }
    var template = texts[key][lang];
    if (params) {
        return template.replace(/%s/g, () => {
            return params.shift();
        });
    } else {
        return template;
    }
}


module.exports = { translate }