<template>
    <div class="table-invoices">
        <h2 class="title">Factures de l'utilisateur {{ userId }}</h2>
        <videotek-table v-if="invoices && component" :child="component" :dataset="invoices" :pages="pages" :limit="limit" :offset="offset" @update="update" @paginate="paginate"></videotek-table>
    </div>
</template>

<script>
import VideotekTable from '../generics/videotek-table.vue';
import VideotekInvoice from './videotek-invoice.vue';


export default {
    name: 'VideotekTableInvoices',
    components: { VideotekTable, VideotekInvoice },
    props:{
        userId: String
    },
    data(){
        return {      
            invoices: [],
            component: VideotekInvoice,
            pages: [10,20,50],
            limit: 10,
            offset: 0,
            _userId: undefined
        }
    },
    watch:{
        userId:function(old, newValue){
            this._userId = this.userId;
            this.getInvoices();
        }
    },
    methods:{
        getInvoices(){
            this.$admins.getInvoices(this._userId, this.limit, this.offset).then(data => {
                this.invoices = data;
            }).catch(error => {
                console.log(error);
            });
        },
        update(limit, offset){
            this.offset = offset;
            this.limit = limit;
            this.getInvoices();
            
        },
        paginate(limit){
            this.limit = limit;
            this.getInvoices();
        }
    },
    mounted(){
        this._userId = this.userId;
        this.getInvoices();        
    }
}
</script>

<style scoped lang="scss">

@import "../../assets/style.scss";

.table-invoices{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    background-color: white;
    transition: box-shadow 0.5s;
    border-radius: 5px;
    margin-top: 12px;
}

.table-invoices:hover{
  box-shadow: 0px 2px 10px 2px rgb(202, 216, 219);
}

.title{
    margin-bottom: 0;
    color: $text;
    font-size: medium;
    margin-left: 8px;
}
</style>