const httpUtils = require('./http-utils.js');

function deleteMedia(mediaGroupId, mediaId) {
    return httpUtils._delete(`/mediaGroups/${mediaGroupId}/medias/${mediaId}/`);
}

function deleteGroup(mediaGroupId) {
    return httpUtils._delete(`/mediaGroups/${mediaGroupId}`);
}

function cloneGroup(mediaGroupId, mediaId) {
    return httpUtils._post(`/mediaGroups/${mediaGroupId}/medias/${mediaId}/clone`, null);
}

function updateGroup(mediaGroupId, data) {
    return httpUtils._patch(`/mediaGroups/${mediaGroupId}`, data);
}

function getGroup(mediaGroupId) {
    return httpUtils._get(`/mediaGroups/${mediaGroupId}?filter[include]=medias`);
}

function getOriginal(mediaId) {
    return httpUtils._getUrl(`/medias/${mediaId}/download`);
}

function getCover(mediaId) {
    return httpUtils._getUrl(`/medias/${mediaId}/downloadCover`);
}

function getPreview(mediaId) {
    return httpUtils._getUrl(`/medias/${mediaId}/downloadPreview`);
}

function getChangeStream(collectionId, ondata, source, cb) {
    var token = sessionStorage.getItem('user_token');
    var filter = {
        include: {
            relation: 'mediaGroup',
            scope: {
                include: {
                    relation: 'collection',
                    scope: {
                        where: { collectionId }
                    }
                }
            }
        }
    }
    var url = httpUtils.BASE_URL + '/medias/change-stream?_format=event-stream&access_token=' + token + '&options=' + encodeURIComponent(JSON.stringify(filter));
    var evtSource = new EventSource(url);
    evtSource.addEventListener('data', (message) => {
        var obj = JSON.parse(message.data);
        ondata(obj.data);
        if (obj.data.status == 'valid') {
            setTimeout(() => {
                cb(obj.data);
            }, 2000);
        }
    });
    source(evtSource);
}

module.exports = { deleteMedia, deleteGroup, cloneGroup, updateGroup, getGroup, getOriginal, getCover, getPreview, getChangeStream }