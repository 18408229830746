<template>
    <div class="admin-user">
        <h2 class="title">Administration de l'utilisateur {{user.name }}</h2>
        <div class="update-credits">
            Crédits:
            <videotek-input typeInput="number" :inputValue="credits" v-model="credits"></videotek-input>
            <videotek-button text="Mettre à jour"  @click="updateCredits"></videotek-button>
        </div>
    </div>
</template>

<script>

import VideotekButton from '../generics/videotek-button.vue';
import VideotekInput from '../generics/videotek-input.vue';

export default{
    name: 'VideotekAdminUser',
    components: { VideotekButton, VideotekInput },
    props:{
        user: Object
    },
    data(){
        return {
            credits: 0
        }
    },
    methods:{
        updateCredits(){
            this.$payments.updateCredits(this.user.wallet.id, this.credits).then(data =>{
                this.$utils.success('Succès', 'Mise à jour effectuée');
                setTimeout(()=>{
                    this.$router.go();
                }, 2000);
            }).catch(error =>{
                this.$utils.error('Oups !', error);
                console.log(error)
            });
        }
    },
    mounted(){
        setTimeout(()=>{
            this.credits = this.user.wallet.credits;
        }, 200);
    },
    watch:{
        user: function(old, newValue){
            this.credits = newValue;
        }
    }
}
</script>

<style lang="scss" scoped>

@import "../../assets/style.scss";

.admin-user{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    background-color: white;
    transition: box-shadow 0.5s;
    border-radius: 5px;
    margin-top: 12px;
}

.title{
    margin-bottom: 0;
    color: $text;
    font-size: medium;
    margin-left: 8px;
}

.update-credits{
    display: flex;
    width: 70%;
    margin: 12px 0 12px 8px;
    align-items: center;
}
.button{
    width: 100%;
}
</style>