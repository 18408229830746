<template>
    <div v-if="datas" class="invoice">
        <div class="invoice-infos">
            <div class="invoice-ids">
                <div class="invoice-id">
                    ID: {{datas.id}} 
                </div>
                <div class="invoice-date"> 
                    Date: {{datas.date}} 
                </div>
            </div>
            <div class="invoice-details">
               {{ datas.address }}
            </div>
            <div class="invoice-details">
               {{ datas.city }}
            </div>
        </div>      
        <div class="invoice-infos">
            <div class="invoices-amounts">
                <div class="invoice-id bottom">
                    Montants facturés
                </div>
                <div class="invoices-prices">
                    {{datas.credits}} crédits
                </div>
                <div class="invoices-prices">
                    {{(datas.priceTTC).toString().length == 1 ? datas.priceTTC.toFixed(2) : datas.priceTTC}} TTC 
                    | {{(datas.priceHT).toString().length == 1 ? datas.priceHT.toFixed(2) : datas.priceHT}} HT
                    | {{ datas.unitPrice }} € / unité
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'VideotekInvoice',
    props:{
        datas: Object
    }
}
</script>

<style lang="scss" scoped>

@import "../../assets/style.scss";

.invoice{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    height: fit-content;
    font-size: medium;
}

.invoice:nth-child(even) {background: rgb(238, 238, 238)}
.invoice:nth-child(odd) {background: #FFF}
.invoice:hover{
    cursor: pointer;
    background-color:rgb(208, 208, 208);
}
.invoice-infos{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 8px;
}

.invoices-prices{
    margin-bottom: 8px;
}

.invoice-ids{
    display: flex;
    flex-direction: column;
}

.invoice-date{
    font-size: small;
    color: $hover-grey
}

.invoice-id{
    color: $primary-dark;
}

.invoice-details{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.bottom{
    margin-bottom: 12px;
}
</style>