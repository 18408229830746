<template>
    <div v-if="datas" class="user" @click="emit">
        <div class="user-infos">
            <div class="user-ids">
                <div class="user-name">
                    Nom: {{datas.name}} 
                </div>
                <div class="user-id">
                    ID: {{datas.id}} 
                </div>
            </div>
            <div class="user-details">
                <div class="user-type">
                    Email: {{datas.email}}
                </div> 
                <div class="user-detail">
                    Vérifié: {{datas.emailVerified}}
                </div>
            </div>
        </div>
        <div class="user-infos">
            <div class="user-wallet">
                Wallet: {{datas.wallet.id }}
            </div>
            <div class="user-amounts">
                <div class="user-prices">
                    {{datas.wallet.credits}} Crédits
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
export default{
    name:'VideotekUser',
    emits:['action'],
    props:{
        datas: Object
    },
    data(){
        return {}
    },
    methods:{
        emit(){
            this.$emit('action',this.datas);
        }
    }
}
</script>

<style lang="scss" scoped>

@import "../../assets/style.scss";

.user{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    height: fit-content;
    font-size: medium;
}

.user:nth-child(even) {background: rgb(238, 238, 238)}
.user:nth-child(odd) {background: #FFF}
.user:hover{
    cursor: pointer;
    background-color:rgb(208, 208, 208);
}
.user-infos{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 8px;
}

.user-amounts{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 8px;
}

.user-ids{
    display: flex;
    flex-direction: column;
}

.user-details{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.user-id{
    font-size: small;
    color: $hover-grey
}

.user-prices{
    margin-bottom: 8px;
}

.user-name{
    color: $primary-dark;
}

.user-type{
    margin-bottom: 8px;
}

.user-wallet{
    color: $primary-dark;
}
</style>