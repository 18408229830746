<template>
<div class="divMenu">
  <div class="header">
    <img v-if="!resize" src="../../assets/logo_videotek.svg" alt="Videotek by Weaverize" @click="main"/>
    <img v-if="resize" src="../../assets/logo_videotek_phone.png" alt="Videotek by Weaverize" @click="main"/>
    <div class="header-button">
      <div class="profil-button" @click="profil">
        <h2>
          [Administrateur] {{ user.name }}
        </h2>
        <span class="material-icons"> person </span>
      </div>
      <div class="logout-button" @click="logout">
        <videotek-button iconNoAnim text="power_settings_new"></videotek-button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import VideotekButton from '../generics/videotek-button.vue';

export default {

  name: "VideotekMenu",
  components: { VideotekButton },
  data() {
      return {
          user:{},
          resize: false
      }
  },
  methods: {
    logout() {
      this.$logout().then(()=> {
        clearInterval(sessionStorage.getItem('schedule'));
        sessionStorage.clear();
        this.$router.push("/");
      });
    },
    profil() {
      this.$router.push("/profil");
    },
    main() {
      this.$router.push("/admin");
    },
  }, 
  mounted(){
      this.$users.me().then(data =>{
          this.user = data.user;
      });
      this.resize = window.innerWidth <= 720;
      window.addEventListener('resize', ()=>{
        this.resize = window.innerWidth <= 720;
      });
    }
};
</script>

<style scoped lang="scss">
@import "../../assets/style.scss";
.divMenu{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header{
    width: 100%;
    background-color: $primary-dark;
    color: white;
    font-family: $font-family;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

img{
  height: 30px;
  margin-left: 20px;
  filter: invert(100%) brightness(200%);
  padding: 10px 0;
  transition: filter 0.3s;
  margin-right:20px
}

img:hover{
  cursor: pointer;
  filter: invert(0%) brightness(0%);
}

.header-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

h2{
  font-size: 12pt;
  text-align: right;
  margin-right: 10px;
}

.profil-button{
  display: flex;
  color: white;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  transition: color 0.3s;
}
.profil-button:hover {
  color: $text;
  cursor: pointer;
}
.material-icons {
  font-size: 16pt;
}
.logout-button {
  margin-right: 15px;
}

@media all and (max-width: 720px){
  .header{
    justify-content: center;
  }
}

</style>