<template>
  <div v-if="icon" class="button-style-icon">
    <span :id="id" class="material-icons">
      {{ text }}
    </span>
  </div>
  <div v-else-if="iconNoAnim" class="button-style-no-anim">
    <span :id="id" class="material-icons">
      {{ text }}
    </span><br>
  </div>
  <div v-else-if="iconBadge" class="button-style-badge">
    <span :id="id" class="material-icons">
      {{ text }}
    </span><br>{{ subText }}
  </div>
  <div v-else class="button">
    <label :id="id" class="button-style">
      {{ text }}
    </label>
  </div>
</template>

<script>
export default {
  name: "VideotekButton",
  props: {
    text: String,
    subText: String,
    icon: Boolean,
    iconNoAnim: Boolean,
    iconBadge: Boolean,
    buttonType: String,
    submitForm: Boolean
  },
  data(){
    return {
      form: undefined,
      interval: undefined,
      id: parseInt(new Date().getTime() + Math.random() * new Date().getTime()).toString()
    }
  },
  mounted(){
    if(this.submitForm){
      this.interval = setInterval(()=>{
        if(this.form){
          clearInterval(this.interval);
        }else{
          if(this.id){
            var me = document.getElementById(this.id);
            var form = me.closest('form');
            if(form){
              this.form = form;
              var submitEvent = new SubmitEvent("submit", { submitter: me });
              me.addEventListener('click', (event)=>{
                this.form.dispatchEvent(submitEvent);
              });
            }
          }
        }
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
.button-style-icon {
  color: white;
  background-color: $primary-dark;
  width: fit-content;
  margin: 10px;
  border-radius: 100%;
  box-shadow: 0px 2px 0px -2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s, box-shadow 0.5s, transform 0.3s;
}
.button-style-icon:hover {
  box-shadow: 0px 2px 20px -3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-color: $text;
  transform: scale(1.3) perspective(1px);
}
.button-style-no-anim {
  color: white;
  background-color: $primary-dark;
  width: fit-content;
  text-align: center;
  border-radius: 100%;
  box-shadow: 0px 2px 0px -2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s, box-shadow 0.5s;
}
.button-style-no-anim:hover {
  box-shadow: 0px 2px 20px -3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-color: $text;
}
.button-style-badge {
  color: $text;
  padding: 5px;
  font-size: small;
  width: fit-content;
  min-width: 65px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 2px 0px -2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s, box-shadow 0.5s, color 0.2s;

  .material-icons{
    font-size: 16pt;
  }
}
.button-style-badge:hover {
  color: white;
  box-shadow: 0px 2px 20px -3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-color: $text;
}
.button-style {
  color: white;
  background-color: $primary-dark;
  width: fit-content;
  margin: auto;
  padding: 10px;
  font-size: medium;
  border-radius: 5px;
  transition: background-color 0.5s, box-shadow 0.5s, transform 0.3s;
}
.button-style:hover {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-color: $text;
}
.material-icons {
  padding: 5px;
}
.button{
  display: flex;
  padding: 12px;
}
label{
  width: fit-content;
  margin: auto;
  padding: 10px;
  border-radius: 5px;
  font-size: medium;
}
</style>