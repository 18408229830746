const url = "https://api-adresse.data.gouv.fr/search/";

function search(address, cb) {

    var params = {
        method: 'GET',
        headers: new Headers(),
        mode: 'cors',
        cache: 'default'
    };
    fetch(`${url}?q=${address.toLowerCase().split(' ').join('+')}`, params).then(response => {
        (async () => {
            var data = await response.json();
            if (data && data.features) {
                var results = []
                data.features.forEach(feature => {
                    results.push(feature.properties)
                });
                cb(results);
            }
        })();
    }).catch(error => {
        cb([]);
    });
}

module.exports = { search }