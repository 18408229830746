<template>
<div>
<videotek-menu></videotek-menu>
    <div v-if="file" class="download-form"> 
         <div class="param-header"><div id="arrow-back" class="material-icons" @click="backMain">
      arrow_back
    </div>
    <div class="title">Téléchargement de fichier</div>
    </div>
        <div class="download-content">
            <h3>Fichier :</h3>
            <p>{{file.name}}</p>
            <h3>Format du fichier :</h3>
            <div class="format" v-for="(format, index) in formats" :key="index">
                <input type="radio" :id="format" name="format" :checked="this.file.name.split('\.')[1] == format" @change="setFormat(format)">
                <label :for="format">{{ format }} {{this.baseFormat == format ? ' - par défaut' : ''}}</label>
            </div>
            <br>
            <div class="alert-payment" v-if="pay">
                *La conversion du fichier entraîne des frais supplémentaires
            </div>
            <br>
            <div class="download-button" @click="download">
                Télécharger le fichier
            </div>
            <br>
            <div class="alert-download" v-if="downloading">Le téléchargement du fichier va démarrer</div>
        </div>
    </div>
    </div>
</template>

<script>
    import VideotekMenu from "./videotek-menu.vue";

    export default {
        name: 'VideotekDownload',
        components : {
            VideotekMenu
        },
        data(){
            return {
                file: undefined,
                formats: ['avi', 'mp4', 'mkv', 'mov'],
                selectedFormat: undefined,
                baseFormat: undefined,
                pay: false,
                downloading: false
            }
        },
        methods: {
            setFormat(format){
                this.pay = format != this.baseFormat;
            },
            backMain() {
            this.$router.push("/main");
             },
            download(){
                this.downloading = true;
                var url = this.$medias.getMediaUrl(this.file.id);
                var link = document.createElement("a");
                link.download = this.file.name;
                link.target = "_parent";
                link.href = url;
                document.body.appendChild(link);
                setTimeout(()=>{
                    link.click();
                    document.body.removeChild(link);
                }, 500);
            }
        },
        mounted(){
            this.file = JSON.parse(localStorage.getItem('download'));
            if(this.file){
                this.baseFormat = this.file.name.split('\.')[1];
                localStorage.clear();
            }else{
                this.$router.push('/main');
            }
        }
    }
</script>


<style lang="scss" scoped>
@import "../assets/style.scss";

    .download-form{
        display: flex;
        flex-direction: column;
        width: 500px;
        margin-top: 100px;
        background-color: $background-dark;
        margin-left: auto;
        margin-right:auto;


        .download-content{
        background-color: $background;
        padding:20px;
        margin:20px;
        border-radius: 10px;

        .alert-payment{
            color: red;
        }
        .alert-download{
            width:fit-content;
            color: $primary-dark;
            margin-right: auto;
            margin-left: auto;
        }
        }

        .format{
            display: flex;
        }

        .download-button{
            background-color: $primary;
            border-radius: 10px;
            border: solid 3px $background;
            width: fit-content;
            padding: 5px 10px;
            margin-right: auto;
            margin-left: auto;

        }

        .download-button:hover{
            border: solid 3px $primary;
            background-color: $text;
            color: white;
            cursor: pointer;
        }
    }
    .param-header{
  background-color: $primary-dark;
    display: flex;
  flex-direction: row;
  align-items: center;
  padding:10px;

     #arrow-back {
    width: fit-content;
    border-radius: 100%;
    color: white;
    padding: 2px;
    border: solid 2px #1a252973;
    font-size:x-large;
    margin: 0px;
  }
  #arrow-back:hover {
    background-color: $text;
    border-color: $primary;
    cursor: pointer;
  }

  .title{
    color:white;
     font-size: large;
    margin-right: auto;
    margin-left: auto;
    padding-right:32px;
  }

}
</style>
