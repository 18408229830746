<template>
  <div v-if="texts" class="login">
    <img
      id="login-logo"
      src="../../assets/logo_videotek.svg"
      alt="Videotek by Weaverize"
    />
    <form @submit.prevent="login" class="login-form">
      <videotek-input :placeholderText="this.$i18n.translate(texts, 'emailAddress')" v-model="email" typeInput="email"></videotek-input>
      <videotek-input typeInput="password" :placeholderText="this.$i18n.translate(texts, 'password')" v-model="password"></videotek-input>
      <router-link class="forgot-password" to="/reset">
        {{ this.$i18n.translate(texts, 'forgotPassword')}}
      </router-link>
      <input id="login" type="submit" value="Connexion" hidden />
      <videotek-button for="login" :text="this.$i18n.translate(texts, 'connect')" :submitForm="true"></videotek-button>
    </form>
    <div class="register">
      <input id="register" type="submit" value="S'inscrire" hidden/>
      <label>{{this.$i18n.translate(texts, 'notRegistered')}}</label> <label @click="register" class="register-link" for="register">{{this.$i18n.translate(texts, 'register')}}</label>
    </div>
    <videotek-lang></videotek-lang>
  </div>
</template>

<script>

import VideotekInput from "../generics/videotek-input.vue"
import VideotekButton from "../generics/videotek-button.vue"
import VideotekLang from "../generics/videotek-lang.vue";

export default {
  name: "VideotekLogin",
  props: {},
  components: { VideotekInput, VideotekButton, VideotekLang },
  data() {
    return {
      email: "",
      password: "",
      texts: undefined
    };
  },
  methods: {
    login() {
      var credentials = {
        email: this.email,
        password: this.password,
      };
      this.$login(credentials).then(() => {
          this.$router.push("/main");
      }).catch(() => {
          delete sessionStorage.user_token;
          this.$router.push("/");
      });
    },
    register(){
      this.$router.push("/register");
    },
    reset(){
      this.$router.push("/reset");
    }
  },
  mounted(){
    this.texts = require('./videotek-login.json');
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";

.login {
  background-color: $background;
  color: $text;
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  max-width: 500px;
  border-radius: 20px;
  font-family: $font-family;
  padding-bottom: 20px;
}
h1 {
  font-size: 20px;
  margin-top: 20px;
}

#login-logo {
  max-width: 60%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.login-form {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password{
  font-size: small;
  margin-bottom: 10px;
  width: 80%;
  text-align: center;
  color: $primary-dark;
}
.forgot-password:hover{
  color: $button;
}

input {
  margin-bottom: 20px;
  font-family: $font-family;
  width: 80%;
}
.login-button {
  margin-top: 18px;
  padding: 10px;
  align-items: center;
  background: $primary;
  margin-bottom: 20px;
  color: white;
  border-radius: 10px;
  border: solid 2px $primary;
  width: 60%;
  text-align: center;
}

.login-button:hover{
  color: $primary;
  background-color: white;
  cursor:pointer;
}


.login-button:active{
  color: $primary;
  background-color:$primary-dark;
  cursor:pointer;
}

.register-link {
  text-decoration: underline;
  color: $primary-dark;
}

.register-link:hover {
  cursor: pointer;
  color: $button;
}

.register-link:active {
  color: $primary-dark;
}

.register{
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: small;
}

@media all and (max-width: 720px) {
  .login{
    width: 100%;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    max-width: initial;
    height: 100vh;
  }
}
</style>
