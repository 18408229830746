<template>
    <video>
      <source v-bind:type="typeVideo" v-bind:src="sourceVideo" />
      Sorry, your browser doesn't support embedded videos.
    </video>
</template>

<script>
export default {
    name: "VideotekVideoComponent",
  props: {
    sourceVideo: String,
    typeVideo: String,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>