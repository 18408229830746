<template>
<div v-if="texts" class="divMenu">
  <div class="header">
    <img class="logo" v-if="!resize" src="../assets/logo_videotek.svg" alt="Videotek by Weaverize" @click="main"/>
    <img class="logo" v-if="resize" src="../assets/logo_videotek_phone.png" alt="Videotek by Weaverize" @click="main"/>
    <div v-if="user" class="header-button">
      <h2 v-if="user.wallet" class="payment" @click="payment">
        {{ this.$i18n.translate(texts, 'credits', [user.wallet.credits]) }}
      </h2><span>|&nbsp;&nbsp;</span>
      <div class="profil-button">
        <h2 @click="profil">
          {{ user.name }}
        </h2>
        <span v-if="loaded && user.complete" class="material-icons"> person </span>
        <span v-if="loaded && !user.complete" class="material-icons red"> warning </span>
        <videotek-lang></videotek-lang>
      </div>
      <div class="logout-button" @click="logout">
        <videotek-button iconNoAnim text="power_settings_new"></videotek-button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import VideotekButton from './generics/videotek-button.vue';
import VideotekLang from './generics/videotek-lang.vue';

export default {

  name: "VideotekMenu",
  components: { VideotekButton, VideotekLang },
  data() {
      return {
          user:{},
          resize: false,
          loaded: false,
          texts: require('./videotek-menu.json')
      }
  },
  methods: {
    logout() {
      this.$logout().then(()=> {
        clearInterval(sessionStorage.getItem('schedule'));
        sessionStorage.clear();
        this.$router.push("/");
      });
    },
    profil() {
      this.$router.push("/profil");
    },
    payment() {
      this.$router.push("/payment");
    },
    main() {
      this.$router.push("/main");
    }
  }, 
  mounted(){
    this.$users.me().then(data =>{
        this.user = data.user;
        setTimeout(()=>{
          this.loaded = true;
        }, 250);
        this.$payments.getWallet(this.user.id).then(wallet => {
          this.user.wallet = wallet;
        });
    });
    this.resize = window.innerWidth <= 720;
    window.addEventListener('resize', ()=>{
      this.resize = window.innerWidth <= 720;
    });
    this.texts = require('./videotek-menu.json')
  }
};
</script>

<style scoped lang="scss">

@import "../assets/style.scss";

.red{
  color: red;
  animation: zoom 0.5s infinite alternate;
  font-size: 36px !important;
}
@keyframes zoom {
  0% {
    color: white
  }
  100% {
    color: red;
  }
}
.divMenu{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header{
    width: 100%;
    background-color: $primary-dark;
    color: white;
    font-family: $font-family;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.payment{
  color:white;
  transition: color 0.3s;
}

.payment:hover{
  color: $text;
  cursor: pointer;
}

.logo{
  height: 30px;
  margin-left: 20px;
  filter: invert(100%) brightness(200%);
  padding: 10px 0;
  transition: filter 0.3s;
  margin-right:20px
}

.logo:hover{
  cursor: pointer;
  filter: invert(0%) brightness(0%);
}

.header-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

h2{
  font-size: 12pt;
  text-align: right;
  margin-right: 10px;
}

.profil-button{
  display: flex;
  color: white;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  transition: color 0.3s;
}
.profil-button h2:hover {
  color: $text;
  cursor: pointer;
}
.material-icons {
  font-size: 16pt;
}
.logout-button {
  margin-right: 15px;
}

@media all and (max-width: 720px){
  .header{
    justify-content: center;
  }
}

</style>