const httpUtils = require('./http-utils.js');
const utils = require('./utils.js');
const users = require('./user-service.js');
const i18n = require('./i18n.js');
const texts = require('../components/videotek-payment.json');

function getWallet(ownerId) {
    return httpUtils._get(`/users/${ownerId}/wallet`);
}

function updateCredits(walletId, amount) {
    return httpUtils._post(`/transactions/admin/credits`, { walletId, amount: parseInt(amount) });
}

/**
 * Opens a session with stripe, 
 * 
 * @param {*} data 
 * @param {*} cb 
 */
function createPayment(data, cb) {

    httpUtils._post('/transactions/payment', data).then(response => {
        cb(null, response);
    }).catch(error => cb(error));
}

/**
 * Injects the stripe iframe
 * @param {*} stripe 
 * @param {*} clientSecret 
 * @param {*} modalConfig 
 * @param {*} cb 
 */
function createStripeForm(stripe, clientSecret, onSubmit, submit) {

    var style = {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d"
            }
        },
        invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    };
    var elements = stripe.elements();
    var card = elements.create('card', { style });
    card.mount('#card-element');
    card.on("change", function (event) {
        document.getElementById("payment-button").disabled = event.empty;
    });

    onSubmit({
        func: payWithCard,
        args: {
            stripe, card, clientSecret
        }
    });

    var form = document.getElementById("payment-form");
    form.addEventListener("submit", function (event) {
        event.preventDefault();
        submit();
    });
}
/**
 * Validates the payment on the stripe side
 * 
 * @param {*} stripe 
 * @param {*} card 
 * @param {*} clientSecret 
 * @param {*} cb 
 */
function payWithCard(args, cb) {
    var stripe = args.stripe;
    var card = args.card;
    var clientSecret = args.clientSecret;
    stripe.confirmCardPayment(clientSecret, {
        payment_method: {
            card: card
        }
    }).then(function (result) {
        if (result.error) {
            utils.error(i18n.translate(texts, 'paymentError'), result.error);
            cb(result.error);
        } else {
            utils.success(i18n.translate(texts, 'paymentOk'), i18n.translate(texts, 'paymentOkResume'));
            cb();
        }
    });
}

function proceedEffect(effectId, media) {
    return new Promise((resolve, reject) => {
        users.me().then(user => {
            httpUtils._post('/transactions/proceed/effect', { effectId, userId: user.user.id, mediaId: media.id, groupId: media.mediaGroupId }).then((response) => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        }).catch(error => {
            reject(error);
        });
    });
}

function getBuyableCredits() {

    return httpUtils._get('/credits');
}

function getBuyableEffects() {

    return httpUtils._get('/effects');
}


module.exports = {
    createStripeForm,
    createPayment,
    updateCredits,
    getWallet,
    getBuyableCredits,
    getBuyableEffects,
    proceedEffect
}