var httpUtils = require('./http-utils.js');

function getUsers(offset, limit) {
    return httpUtils._get(`/users?filter[limit]=${limit}&filter[skip]=${offset}&filter[include]=wallet`);
}

function me() {

    return httpUtils._get('/users/me');
}

function findByName(name) {

    return httpUtils._get(`/users?filter[where][username][like]=${name}&filter[include]=wallet`);
}

function setPassword(token, password) {
    return httpUtils._post('/users/create', { token, password });
}

function register(name, email) {
    return httpUtils._post('/users/register', { name, email });
}

function resetPassword(email) {
    return httpUtils._post('/users/reset', { email });
}

function admin() {
    return httpUtils._get('/users/admin');
}

function getByWalletId(id) {
    return new Promise((resolve, reject) => {
        httpUtils._get(`/wallets/${id}/`).then(wallet => {
            httpUtils._get(`/users/${wallet.ownerId}`).then(user => {
                resolve(user);
            }).catch(err => {
                reject(err);
            });
        }).catch(err => {
            reject(err);
        });
    });
}

function update(id, data) {
    return httpUtils._patch(`/users/${id}`, data);
}

module.exports = { me, findByName, setPassword, register, resetPassword, admin, getByWalletId, getUsers, update };