const toast = require("mosha-vue-toastify");

function error(title, description) {
  toast.createToast(
    { title, description },
    {
      type: "danger",
      timeout: 2000,
      transition: "zoom"
    }
  );
}

function success(title, description) {
  toast.createToast(
    { title, description },
    {
      type: "success",
      timeout: 3000,
      transition: "zoom"
    }
  );
}

function getDuration(duration) {
  if (duration > 60) {
    var t = duration;
    var sec = t - (t % 60);
    var min = sec / 60;
    var s = parseInt(t - sec);
    s = s < 10 ? `0${s}` : s;
    min = min < 10 ? `0${min}` : min;
    return `${min}:${s}`;
  } else {
    var s = parseInt(duration);
    s = s < 10 ? `00:0${s}` : `00:${s}`;
    return s;
  }
}

module.exports = { error, success, getDuration };
