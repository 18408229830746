<template>
<div class="tooltipBox">
  <div class="tool" @mouseover.prevent="showtooltip" @mouseleave.prevent="discardtooltip">
  <div id="tooltip">
  <span class="text">{{ text }}</span>
</div>
</div>
</div>
 
</template>

<script>
export default {
  name: "VideotekTooltip",
  props: { 
    text: String,
  },
  methods: {
    showtooltip(){
      document.getElementById("tooltip").style.opacity="1";
    },
    discardtooltip(){
      document.getElementById("tooltip").style.opacity="0";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style.scss";
.tooltipBox{
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  height:1px;
  width:1px;
   margin-bottom:40px;
}
.tool{
  position: absolute;
  width:44px;
  height:40px;

}
#tooltip { 
  position: absolute;
  text-align: center;
  font-size: small;
  border-radius: 10px;
  width: fit-content;
  padding:5px;
  background: $text;
  right:44px;
  margin-top:8px;
  opacity: 0;
}
.text{
  color:white;
}
</style>