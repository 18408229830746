<template>
    <div>   
        <div v-if=" texts && config.name" :id="config.name" class="modal">      
            <div class="modal-content">
                <div class="header">
                    <div v-if="config.text" class="text" >{{ config.text }}</div>
                    <span v-if="!config.loader">
                        <videotek-button icon text="close" class="close"></videotek-button>
                    </span>
                </div>
                <div v-if="config.subtext" class="subtext">{{ config.subtext }}</div>
                <div v-if="!config.loader" class="buttonsModal">
                    <videotek-button v-if="config.canceled" :text="this.$i18n.translate(texts, 'validate')" :subText="this.$i18n.translate(texts, 'validate')" @click="validate" :for="this.$i18n.translate(texts, 'validate')"></videotek-button>
                    <videotek-button  v-if="config.canceled" :text="this.$i18n.translate(texts, 'cancel')" :subText="this.$i18n.translate(texts, 'cancel')" @click="close" :for="this.$i18n.translate(texts, 'cancel')"></videotek-button>
                    <videotek-button v-if="!config.canceled" :text="this.$i18n.translate(texts, 'ok')" :subText="this.$i18n.translate(texts, 'ok')" @click="validate" :for="this.$i18n.translate(texts, 'ok')"></videotek-button>
                </div>
                <div v-if="config.loader" class="loader">
                    <pulse-loader id="loader" :loading="config.loading" color="#4885ad" size="28px"></pulse-loader>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import VideotekButton from "./videotek-button.vue";

export default {
    name:'VideotekModal',
    components:{ VideotekButton, PulseLoader },
    emits: ['execute'],
    props:{
        config: Object
    },
    data(){
        return {
            modal: undefined,
            texts: require('./videotek-modal.json')
        }
    },
    methods:{
        display(){
            this.modal.style.display = (this.config.show) ? "flex" : "none";
        },
        validate(){
            if(this.config.validate){
                this.$emit('execute',this.config.validate);
            }
            this.modal.style.display =  "none";
        },
        close(){
            if(this.config.close){
                this.$emit('execute', this.config.close);
            }
            this.modal.style.display = "none";
        }
    },
    watch:{
        config: function(oldValue, newValue){
            setTimeout(()=>{

                this.modal = document.getElementById(this.config.name);
            
                var span = this.modal.children[0].children[0];

                span.onclick = () => {
                    this.close();
                }

                if(!this.config.loader){
                    window.onclick = (event) => {
                        if (event.target.id == this.config.name) {
                            this.close();
                        }
                    }
                }
               
                this.display();

            }, 16);  
        }
    },
    mounted(){
        this.texts = require('./videotek-modal.json');
    }
}
</script>


<style scoped lang="scss">
@import "../../assets/style.scss";

.modal {
  display: none; 
  flex-direction: column;
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);
}
.buttonsModal{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}
p{
    margin-top: 35px;
    text-align: center;
}
.modal-content {
  position:relative;
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 15px;
  width: 40%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}
.header{
    display: flex;
    justify-content: space-between;
}
.close {
  color: rgb(255, 255, 255);
  margin-top: 0;
}

.close:hover,
.close:focus {
  cursor: pointer;
}
.text{
    font-size: medium;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    color: $text;
    text-align: center;
    width: 100%;
}
.subtext{
    text-align: center;
    font-size: small;
    margin-bottom: 18px;
}
.loader{
   text-align: center;
}

@media all and(max-width: 720px){
    .modal-content{
        width: 80%;
    }
}
</style>
