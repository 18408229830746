<template>
    <div v-if="name && options" class="select">
        <select v-model="selected" @click="select">
            <option v-for="(option, index) in options" :key="index" :value="option">{{option}}</option>
        </select>
    </div>
</template>

<script>

export default{
    name: 'VideotekSelect',
    emits:['selected'],
    props:{
        name: String,
        options: Array
    },
    data(){
        return {
            selected:undefined
        }
    },
    methods:{
        select(){
            this.emit();
        },
        emit(){
            this.$emit('selected', this.selected);
        }
    },
    mounted(){
        this.selected = this.options[0];
        this.emit();
    }
}
</script>

<style lang="scss" scoped>
select{
    font-family: 'Roboto';
    font-weight: bolder;
    padding: 5px 10px;
    margin: 5px 15px;
    background-color: rgba(255, 255, 255, 0);
    border:solid 1px rgba(255, 255, 255, 0);
    transition: background-color 0.3s, border-bottom 0.2s, border-radius 0.2s, border 0.3s;
    border-bottom: solid 1px rgb(148, 171, 192);
}
select:hover{
    outline: none;
    background-color: rgba(255, 255, 255, 0.8);
    border: solid 1px rgb(148, 171, 192);
    border-radius: 5px;
    box-shadow: 0px 1px 10px -5px ;
}
select:focus{
    outline: none;
    background-color: rgba(255, 255, 255, 0.8);
    border: solid 1px rgb(148, 171, 192);
    border-radius: 5px;
}
</style>
