<template>
    <div>
        <videotek-menu></videotek-menu>
        <div v-if="texts && mediaPlayed" class="edit-form">
            <div class="title">
                <div class="back-button">
                    <videotek-button iconNoAnim text="arrow_back" @click="()=>{this.$router.push('/')}"></videotek-button>
                </div>
                <h3>{{ this.$i18n.translate(texts, 'editVideo', [this.mediaPlayed.name ]) }} </h3>
            </div>
            <videotek-video-player :sourceVideo="getUrl()" :duration="mediaPlayed.data.length"></videotek-video-player>
            <p>
                {{ this.$i18n.translate(texts, 'selectEffect') }} 
            </p>
            <div v-if="effects" class="effects">
                <div v-for="(effect, index) in effects" :key="index" class="effect">
                    <input :for="effect.price" type="radio" name="effects" :id="effect.id" :value="effect.price" @change="selectEffect(effect)">
                    <div class="effect-name"> {{ effect.name }} </div>
                    <div v-if="effect.description" class="effect-description"> {{ effect.description }} </div>
                    <div class="effect-price">({{ effect.price }} {{this.$i18n.translate(texts, 'credits')}})</div>
                </div>
            </div>
            <videotek-button v-if="selectedEffect" :text="this.$i18n.translate(texts, 'submit')" buttonType="submit" @click="confirm"></videotek-button>
        </div>
        <videotek-modal :config="modalConfig" @execute="execute"></videotek-modal>
    </div>    
</template>

<script>
import VideotekButton from "./generics/videotek-button.vue";
import VideotekMenu from "./videotek-menu.vue";
import VideotekModal from "./generics/videotek-modal.vue";
import VideotekVideoPlayer from "./video/videotek-video-player.vue"

export default {
    components: { VideotekButton, VideotekMenu, VideotekModal, VideotekVideoPlayer },
    name: 'EditForm',
    data(){
        return {
            effects: undefined,
            mediaPlayed: undefined,
            selectedEffect: undefined,
            modalConfig: {},
            texts: require('./videotek-editor.json')
        }
    },
    methods:{
        execute(action){
            this[action]();
        },
        selectEffect(effect){
            this.selectedEffect = effect;
        },
        confirm(){
            var self = this;
            this.modalConfig = {
                name: 'confirmEdit',
                text: this.$i18n.translate(self.texts, 'editingOf', [this.mediaPlayed.name]),
                subtext: this.$i18n.translate(self.texts, 'applyEffect', [this.selectedEffect.name, this.selectedEffect.price]),
                validate: 'proceed',
                canceled: true,
                show: true
            }
        },
        proceed(){
            this.$payments.proceedEffect(this.selectedEffect.id, this.mediaPlayed).then((response)=>{
                if(response === false){
                    this.$router.push("/payment")
                }else{
                    delete sessionStorage.media;
                    sessionStorage.setItem('scheduleEditings', true);
                    this.$utils.success(this.$i18n.translate(this.texts, 'buySuccess'),this.$i18n.translate(this.texts, 'buyResume') );
                    this.$router.push("/");
                }
            }).catch(() => {
                this.$router.push("/");
            });
        },
        getUrl() {
            return this.$medias.getPreview(this.mediaPlayed.id);
        }
    },
    mounted(){
        this.mediaPlayed = JSON.parse(sessionStorage.getItem('media'));
        if(this.mediaPlayed){
            this.$payments.getBuyableEffects().then(data=>{
                this.effects = data;
            });
        }else{
            this.$router.push("/");
        }
        this.texts = require('./videotek-editor.json');
    }
}
</script>

<style lang="scss" scoped>

@import "../assets/style.scss";


.edit-form{
    display: flex;
    flex-direction: column;
    background-color: $background;
    margin: 60px auto;
    width: 60%;
    max-width: 600px;
    border-radius: 5px;
}

.title{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: $primary-dark;
    color: white;
    border-radius: 5px 5px 0 0;
}

.back-button{
    margin-left: 8px;
}

h3{
    margin-left: 20px;
    font-size: medium;
}
p{
    margin-left: 20px;
    font-size: medium;
}
.effects{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 18px;
}

.effect{
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
    margin-left: 28px;
}
.effect-name{
    padding: 8px;
    color: $text;
    font-size:small;

}

.effect-description{
    font-size:small;
    background-color: $main-background
}

.effect-price{
    font-size:small;
    padding: 8px;
}

.edit-form{

    transition: box-shadow 0.2s;
}

.edit-form:hover{
    box-shadow: 0px 2px 10px 2px rgb(202, 216, 219);
}

@media all and(max-width: 720px) {
    .edit-form{
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        margin: 0;
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        height: 100vh;
    }
    .title{
        border-radius:0;
        background-color: white;
        color: $text;
    }
    h3{
        margin-left: 8px;
        font-weight: normal;
        font-size: medium;
    }
    .contain{
        max-height: 300px;
    }
}
</style>