<template>
  <div>
      <router-view></router-view>
  </div>
</template>

<script>

  import 'mosha-vue-toastify/dist/style.css'

  export default {
    name:'App',
    components: {},
    mounted(){
      if(!sessionStorage.getItem('lang') && navigator.language){
        if(navigator.language.includes('fr')){
          sessionStorage.setItem('lang', 'fr')
        }else{
          sessionStorage.setItem('lang', 'en');
        }
      }
    }
  }
</script>

<style lang="scss">
  
  @import "./assets/style.scss";

  #app {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body{
    font-family: $font-family, sans-serif;
    margin: 0;
    padding: 0;
    background-color: $main-background;
    user-select: none;
  }
</style>
