<template>
    <div class="lang">
        <img class="flag" v-if="!show('fr')" src="../../assets/fr.svg" @click="localize('fr')" alt="Fr"/>
        <img class="flag" v-if="!show('en')" src="../../assets/gb.svg" @click="localize('en')" alt="En"/>
    </div>
</template>

<script>
export default {
    name: "VideotekLang",
    emits:['translate'],
    props:{
        texts: Object
    },
    data(){
        return {
            _texts: undefined
        }
    },
    methods:{
        localize(lang) {
            sessionStorage.setItem('lang', lang);
            this.$router.go();
        },
        show(lang){
            return sessionStorage.getItem('lang') == lang;
        }
    },
    watch:{
        texts: function(oldValue, newValue){
            this._texts = newValue;
        }
    },
    mounted(){
        this._texts = this.texts;
        if(!sessionStorage.getItem('lang')){
            sessionStorage.setItem('lang', 'fr');
        }
        
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";
.lang{
    display: flex;
    justify-content: center;
    align-items: center;
}
.flag{
    height: 20px;
    margin: 4px;
}

.flag:hover{
  cursor: pointer;
}
</style>