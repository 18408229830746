<template>
  <div v-if="user" class="home">
    <videotek-menu></videotek-menu> 
    <div class="main-container">
        <videotek-media-groups v-bind:class="{ 'media-groups-closed': isGroupOpen }" v-if="collection" :collection="collection" @mediaGroupChange="mediaGroupChange" @refresh="getCollection"></videotek-media-groups>   
        <videotek-media-group v-bind:class="{ 'media-group-open': isGroupOpen }" v-if="mediaGroup"  :mediaGroup="mediaGroup" @reloadGroups="getCollection" @renameGroup="renameGroup"></videotek-media-group>    
        <videotek-button v-if="showSlide && mediaGroup && isGroupOpen" @click="moveToGroup"
        v-bind:class="{ groupButtonOpened: isGroupOpen }"
        class="groupButton" iconNoAnim text="chevron_left"></videotek-button>
    </div>
  </div>
</template>

<script>
import VideotekMediaGroups from "./videotek-media-groups.vue";
import VideotekMediaGroup from "./videotek-media-group.vue";
import VideotekMenu from "./videotek-menu.vue";
import VideotekButton from './generics/videotek-button.vue';

export default {
    components: { VideotekMediaGroups, VideotekMediaGroup, VideotekMenu, VideotekButton },
    name:'Main',
    props:{},
    data() {
        return {
            user:{},
            collection: undefined,
            mediaGroup: undefined,
            isGroupOpen: false,
            showSlide: false
        }
    },
    methods:{
        mediaGroupChange(mediaGroup){
            this.mediaGroup = mediaGroup;
            this.moveToGroup();
        },
        getCollection(){
          if(this.isGroupOpen){
            this.moveToGroup();
          }
          setTimeout(()=>{
              this.$collections.getCollection(this.user.id).then(collection => {                   
                this.collection = collection;
                this.mediaGroup = undefined;
                if(sessionStorage.cached){
                  var cached = JSON.parse(sessionStorage.getItem('cached'));
                  this.$medias.getGroup(cached.id).then(data => {          
                    this.mediaGroup = data;   
                  });
                }
                this.collection.mediaGroups.forEach(group => group.show = "true");
              });
          }, 300);    
        },
        renameGroup(id, name){
            this.collection.mediaGroups.forEach(mediaGroup => {
                if(mediaGroup.id == id){
                    mediaGroup.name = name;
                }
            })
        },
        moveToGroup() {
          this.isGroupOpen = !this.isGroupOpen;
        }

    },
    mounted(){
      this.showSlide = window.innerWidth <= 720;
      window.addEventListener('resize', ()=>{
        this.showSlide = window.innerWidth <= 720;
      });
      this.$users.me().then(data => { 
        this.user = data.user;
        this.$payments.getWallet(this.user.id).then(wallet=>{
          this.user.wallet = wallet;
        });
        this.getCollection();        
      }).catch(() => {
        sessionStorage.clear();
        this.$router.push("/");
      });
    }
};
</script>

<style scoped lang="scss">
@import "../assets/style.scss";

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.main-container {
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.media-groups{

  transition: box-shadow 0.2s;
}
.media-groups:hover{
  box-shadow: 0px 2px 10px 2px rgb(202, 216, 219);
}
.media-group{
  transition: box-shadow 0.2s;
}
.media-group:hover{
  box-shadow: 0px 2px 10px 2px rgb(202, 216, 219);
}
@media all and (max-width: 720px){
  .home{
    height: 100vh;
  }
  .main-container{
    width: 100%;
    flex: 1;
    display: block;
    position: relative;
    overflow: hidden;
    margin:0;
  }
  .groups-container{
    max-height: 100%;
  }
  .media-groups{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: translateX(0%);
    transition: transform 0.8s;
    height: 100vh;
    margin:0;
    max-width: 100%;
  }
  .media-groups-closed {
    transform: translateX(-100%);
  }
  .media-group{
    width: 100%;
    position: absolute;
    transform: translateX(100%);
    transition: all 0.8s;
    left: 0;
    top: 0;
    margin: 0;
    height: 100%;
    background-color: black;
    border-radius: 0;
  }
  .media-group-open{
    transform: translateX(0%);
  }

  #video-div{
    .contain{
      min-height: 300px;
    }
  }
  .groupButton {
    position: absolute;
    top: 90%;
    right: 5%;
    color: white;
    display: flex;
    justify-content: center;
  }
  
  .groupButtonOpened {
    left: 5%;
    color: white;
  }
  
}
</style>
