<template>
  <div v-if="texts" class="contain" @mouseenter="showOptions" @mouseleave="discardOptions" @mousemove="discardPlay">
    <pulse-loader v-if="!ready" id="loader" :loading="true" color="#aaaaaa" size="50px"></pulse-loader>
    <videotek-video-component id="videoComponent" v-bind:type="format" v-bind:src="source"></videotek-video-component>
    <div id="playOver" @click="togglePlay">
      <span class="material-icons button" id="playButtonOver">{{ nameIcon }}</span>
    </div>
    <div id="video-progress">
      <progress id="progress-bar" value="0" min="0"></progress>
      <input class="seek" id="seek" value="0" min="0" type="range" @input="skipAhead">
    </div>
    <div id="options">
      <span @click="togglePlay" class="material-icons button">{{ nameIcon }}</span>
      <span class="time">{{ videoDurationElapsed || "00:00" }} &nbsp;/ {{ videoDuration }}</span>
      <div class="volume-controls">
        <span class="material-icons" id="volumeButton" @click="toggleMute">{{ nameIconVolume }}</span> 
        <div class="volume-progress">
        <progress id="progress-bar-volume" value="1" min="0"></progress>
        <input class="volume" id="volume" value="1" type="range" max="1" min="0" step="0.01" @input="updateVolume">
        </div>
      </div>
      <div class="actions-controls" v-if="actions">  
        <div class="tag"> {{ this.tag }} </div>
        <span v-if="!resize" class="material-icons" @click="this.$emit('action', 'downloadFile')" v-tooltip.center="this.$i18n.translate(texts, 'download')" >download</span>
        <span  v-if="!resize" class="material-icons" @click="this.$emit('action', 'copyFile')" v-tooltip.center="this.$i18n.translate(texts, 'duplicate')">content_copy</span>
        <span  v-if="!resize" class="material-icons" @click="this.$emit('action', 'editFile')" v-tooltip.center="this.$i18n.translate(texts, 'edit')">edit</span>
        <span  v-if="!resize" class="material-icons" @click="this.$emit('action', 'deleteFile')" v-tooltip.center="this.$i18n.translate(texts, 'delete')">delete</span>
        <span  class="material-icons" @click="showOptionsDiv" v-tooltip.top-right="this.$i18n.translate(texts, 'selectVersion')">menu</span> 
      </div>
    </div>
    <div class="options-div" ref="video-option-list">
      <div class="video-option-list" >
        <span>{{ this.$i18n.translate(texts, 'selectVersion') }} :<hr /></span>
        <div class="video-option" v-for="(media, index) in this.versions" @click="loadMedia(media)" :key="index">
          Option {{index + 1}}: {{ media.version }}
        </div>
        <div  class="actions-controls" v-if="actions && resize">  
          <span class="material-icons dark" @click="this.$emit('action', 'downloadFile')" v-tooltip.center="this.$i18n.translate(texts, 'download')" >download</span>
          <span class="material-icons dark" @click="this.$emit('action', 'copyFile')" v-tooltip.center="this.$i18n.translate(texts, 'duplicate')">content_copy</span>
          <span class="material-icons dark" @click="this.$emit('action', 'editFile')" v-tooltip.center="this.$i18n.translate(texts, 'edit')">edit</span>
          <span class="material-icons dark" @click="this.$emit('action', 'deleteFile')" v-tooltip.center="this.$i18n.translate(texts, 'delete')">delete</span>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import VideotekVideoComponent from "./videotek-video-component.vue";
import VTooltip from 'v-tooltip'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  data() {
    return{
      nameIcon: "play_arrow",
      nameIconVolume: "volume_up",
      video: undefined,
      videoDuration: undefined,
      videoDurationElapsed: undefined,
      msg: "Oui",
      videoButtonOption: false,
      versions:undefined,
      source: undefined,
      format: undefined,
      tag: 'Base',
      resize: false,
      ready: false,
      texts: require('./videotek-video-player.json')

    }
  },
  emits: ["menuFile", "action", "mediaChanged"],
  components: { VideotekVideoComponent, VTooltip, PulseLoader },
  props: {
    sourceVideo: String,
    typeVideo: String,
    versionVideo : Array,
    actions: Boolean,
    duration: Number
  },
  methods: {
    skipAhead(event){
      const skipTo = event.target.dataset.seek ? event.target.dataset.seek : event.target.value;
      this.video.currentTime = skipTo;
      this.progressBar.value = skipTo;
      seek.value = skipTo;
    },
    togglePlay() {
      if (this.video.paused || this.video.end) {
        this.nameIcon = "pause";   
        this.video.play();
        this.video.end = false;
      }else {
        this.nameIcon = "play_arrow";
        this.video.pause();
      }
    },
    discardPlay(){
      document.getElementById("options").style.opacity="1"; 
      document.getElementById("playOver").style.opacity="1";
      document.getElementById("video-progress").style.opacity="1";
      setTimeout(function(){
        var playOver = document.getElementById("playOver");
        if(playOver){
          playOver.style.opacity="0"
        }
      }, 1500);
    },
    discardOptions(){
      document.getElementById("options").style.opacity="0";
      document.getElementById("playOver").style.opacity="0"; 
      document.getElementById("video-progress").style.opacity="0";     
    },
    updateVolume(){
      if (this.video.muted) {
        this.video.muted = false;
      }
      this.video.volume = volume.value;
      this.progressBarVolume.value = volume.value;
    },
    showOptionsDiv(){
      if(this.videoButtonOption === false){
        this.$refs["video-option-list"].style.opacity="1";
        this.$refs["video-option-list"].style.zIndex="2";
        this.videoButtonOption = true;  
      } else {
        this.$refs["video-option-list"].style.opacity="0";
        this.$refs["video-option-list"].style.zIndex="-1";
        this.videoButtonOption = false;
      }  
    },
    loadMedia(media) {
      this.tag = media.version;
      this.source = this.getUrl(media);
      var ext = media.name.split('\.')[1];
      this.format = ext ? `video/${ext}` : 'video/mp4';
      this.$emit("mediaChanged", media);
    },
    toggleMute(){
      this.video.muted = !this.video.muted;
      if (this.video.muted) {
          this.volume.setAttribute('data-volume', this.volume.value);
          this.volume.value = 0;
          this.progressBarVolume.value = volume.value;
      } else {
          this.volume.value = volume.dataset.volume;
          this.progressBarVolume.value = volume.value;
      }
    },
    getUrl(media) {
      return this.$medias.getPreview(media.id);
    },
    initVideoPlayer(){
      this.format = this.typeVideo;
      this.source = this.sourceVideo;
      this.versions = this.versionVideo;
      this.video = document.getElementById("videoComponent");
      this.progressBar = document.getElementById('progress-bar');
      this.progressBarVolume = document.getElementById('progress-bar-volume');
      this.volumeButton = document.getElementById('volumeButton');
      this.volume = document.getElementById('volume');
      this.video.onended = ()=>{
        this.video.end = true;
        this.nameIcon = "replay";
      };
      this.video.onvolumechange = ()=>{
        if (this.video.muted || this.video.volume === 0) {
          this.nameIconVolume = "volume_off";
        } else if (this.video.volume > 0 && this.video.volume <= 0.5) {
          this.nameIconVolume = "volume_down";
        } else {
          this.nameIconVolume = "volume_up";
        }
      };
      this.video.preload = 'metadata';
      this.video.addEventListener('loadeddata', this.loadedData);
      this.video.addEventListener('timeupdate', this.timeUpdate);
      this.resize = window.innerWidth <= 870;
      window.addEventListener('resize', (event)=>{
        this.resize = window.innerWidth <= 870;
      });
    },
    loadedData(){
      const seek = document.getElementById('seek');
      this.videoDuration = this.$utils.getDuration(this.duration);
      seek.setAttribute('max', Math.round(this.duration));
      this.progressBar.setAttribute('max', Math.round(this.duration));
      this.progressBarVolume.setAttribute('max', Math.round(this.video.volume));
      this.ready = true;
    },
    timeUpdate(){
      const seek = document.getElementById('seek');
      this.videoDurationElapsed = this.$utils.getDuration(this.video.currentTime); 
      seek.value = Math.round(this.video.currentTime);
      this.progressBar.value = Math.round(this.video.currentTime);
    },
    removeVideoPlayerEvents(){
        this.video.removeEventListener('loadeddata', this.loadedData);
        this.video.removeEventListener('timeupdate', this.timeUpdate);
        this.video = undefined;      
    }
  },
  mounted(){
    this.initVideoPlayer();
    this.texts = require('./videotek-video-player.json');
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/style.scss";

.contain{
  background-color: black;
  width: 100%;
  height:100%;
  min-height:200px;
  position: relative;
  z-index: 0;
}

#options{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.5s;
  .material-icons{
      font-size: clamp(15px, 2vw, 24px);
  }
}

.dark{
  color: $text !important;
}

#playOver{
  position: absolute;
  width:100%;
  height:100%;
  top:0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s;
}

#playButtonOver{
  color: white;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  font-size:100px; 
}

.button{
    cursor: pointer;
    color:white;
    transition: color 0.3s;
}

.button:hover{
    color:$button-hover;
}

.time{
  color:white;
  font-size: small;
  margin-left:5px;
  white-space: nowrap;
}

#video-progress{
  width:100%;
  height: 10px;
  opacity:0;
  transition: opacity 0.5s;
}

#progress-bar{
  width: calc(100% - 2px);
  margin-left:1px;
}

progress{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 5px;
  background-color: #474545;
  border-color: #474545;
  pointer-events: none;
  position: absolute;
  bottom: 40px;
}

progress::-webkit-progress-bar{
  background-color: #474545;
}

progress::-webkit-progress-value{
  background: $primary-dark;
}

progress::-moz-progress-bar{
  background: $primary-dark;
}

.seek{
  position: absolute;
  bottom: 40px;
  width: 100%;
  cursor: pointer;
  margin: 0;
}

input[type=range]{
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 7px;
  background: transparent;
  cursor: pointer;
}

input[type=range]:focus{
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track{
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.4s ease;
}

input[type=range]::-webkit-slider-thumb{
  height: 14px;
  width: 14px;
  border-radius: 16px;
  background: $primary-dark;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type=range]:focus::-webkit-slider-runnable-track{
  background: transparent;
}

input[type=range]::-moz-range-track{
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #474545;
}

input[type=range]::-moz-range-thumb{
  height: 13px;
  width: 13px;
  border-radius: 50px;
  border: 1px solid $primary-dark;
  background: $primary-dark;
  cursor: pointer;
  margin-top: 5px;
}

input[type=range]:focus::-moz-range-track{
  outline: none;
}

input[type=range]::-moz-range-progress{
  background: $primary-dark;
  height:100%;
}

#volumeButton{
  cursor: pointer;
  color:white;
  transition: color 0.3s;
}

#volumeButton:hover{
  color:$button-hover;
}

.volume-controls{
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 10px;
  max-width:75px;
}

.volume-progress{
  position:relative;
  margin-top:5px;
}

#progress-bar-volume{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  bottom:0;
  background-color: #474545;
  border-color: $primary-dark;
  border-style:none;
  pointer-events: none;
  max-width:75px;  
  position: absolute;
}

#progress-bar-volume::-moz-progress-bar{
  background: #474545;
  background-color: $primary-dark;
}

.volume-controls input{
  transition: all 0.4s ease;
}

input[type=range].volume{
  bottom:0;
  max-width: 75px;
  height: 5px;
  background-color: transparent;
  position: absolute;
  margin:0;
}

input[type=range].volume::-webkit-slider-runnable-track{
  background-color: transparent;
}

input[type=range].volume::-webkit-slider-thumb{
  height: 14px;
  width: 14px;
  border-radius: 14px;
  background: $primary-dark;
}

.tag{
  text-align: center;
  background: $primary-dark;
  border-radius: 10px;
  padding: 5px 10px;
}

.actions-controls{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width:100%;
  color:white;
}

.material-icons{
  position:relative;
  cursor: pointer;
  color:white;
  transition: color 0.3s;
  margin-left:10px;
  margin-right:10px;
}

.material-icons:hover{
  color:$button-hover;
}

.video-option-list{
  background: $background;
  border-radius: 10px;
  font-size: small;
  padding: 10px;
  z-index:-100;  
}

.video-option{
  max-width: 120px;
  text-align: center;
  margin: 10px auto 10px auto;
  padding: 5px 10px;
  width: auto;
  background: darksalmon;
  border-radius: 10px;
}

.video-option:hover{
  background: rgb(255, 174, 147);
  cursor:pointer;
}
  
.options-div{
  z-index:-1;
  display: flex;
  flex-direction: column;
  position:absolute;
  bottom:0;
  right:25px;
  border-radius:10px;
  margin-bottom: 60px;
  max-width:200px;
  opacity: 0;
  transition: opacity 0.5s, z-index 0.5s;
  box-shadow: 0px 2px 10px 2px rgba(0,0,0,.5);
}

#loader{
  position: absolute;
  top: calc(50% - 50px);
  vertical-align: top;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 2;
}
</style>