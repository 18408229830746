<template>
    <div v-if="datas && child" class="tab">
        <div class="tab-search">
        <videotek-input  placeholderText="Rechercher ..." v-model="criteria" @input="search" typeInput="text"></videotek-input>
        </div>
        <component :is="child" v-for="(data, index) in datas" :key="index" :datas="data" @action="action"></component>
        <div class="tab-pages">
            Nombre d'éléments: <videotek-select class="pagination" name="page" :options="pages" @selected="select"></videotek-select>
            <videotek-button v-if="_offset" class="button-pages" iconNoAnim text="chevron_left" @click="previous"></videotek-button>
            <videotek-button v-if="datas.length >= _limit" class="button-pages" iconNoAnim text="chevron_right" @click="next"></videotek-button>
        </div>
    </div>
</template>

<script>

import VideotekButton from './videotek-button.vue';
import VideotekInput  from './videotek-input.vue';
import VideotekSelect from './videotek-select.vue';


export default {
    name: 'VideotekTable',
    components: { VideotekButton, VideotekInput, VideotekSelect },
    emits:['update', 'paginate','action'],
    props:{
        child: Object,
        dataset: Array,
        pages: Array,
        limit: Number,
        offset: Number
    },
    data(){
        return {      
            datas: [],
            cached: [],
            criteria: undefined,
            _limit:undefined,
            _offset:undefined
        }
    },
    watch:{
        child:function(old, newValue){
            this.child = newValue;
        },
        dataset:function(old, newValue){
            this.datas = [];
            this.datas = this.dataset;
        },
        limit:function(old, newValue){
            this._limit = this.limit;
        },
        offset:function(old, newValue){
            this._offset = this.offset;
        }
    },
    mounted(){
        this._limit = this.limit;
        this._offset = this.offset;
        this.datas = this.dataset;
    },
    methods:{
        search(){
            if(this.criteria == '' || !this.criteria) {
                this.datas = this.cached.filter(c=> c != null);
                this.cached = [];
                this.searched = [];
            }else{
                if(this.cached.length){
                    this.datas = this.cached.filter(c=> c != null);
                    this.cached = [];
                }
                this.cached = this.datas.filter(t => t!= null);
                var searched = [];
                Object.keys(this.datas[0]).forEach(key => {
                    this.datas.forEach(data => {
                        if(typeof data[key] == 'string'){
                            if(data[key].includes(this.criteria)){
                                searched.push(data);
                            }
                        }else if(typeof data[key] == 'number'){
                            if(String(data[key]).includes(this.criteria)){
                                searched.push(data);
                            }
                        }
                    });
                });
                this.datas = searched.filter(s => s != null);
            }
        },
        select(limit){
            this._limit = limit;
            this.$emit('paginate', this._limit);
        },
        next() {
            this._offset += this._limit;
            this.$emit('update', this._limit, this._offset);
        },
        previous() {
            this._offset -= this._limit;
            this.$emit('update', this._limit, this._offset);
        },
        action(data){
            this.$emit('action', data);
        }
    }
}
</script>

<style scoped lang="scss">

@import "../../assets/style.scss";

.tab{
    display: flex;
    flex-direction: column;
    width: 100%; 
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    max-height: 400px;
    overflow-y: scroll;
    font-family: $font-family;
    background-color: $background-dark;
}

.tab::-webkit-scrollbar-track{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.tab::-webkit-scrollbar{
  width: 6px;
  background-color: #F5F5F5;
}

.tab::-webkit-scrollbar-thumb{
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: $primary-dark;
}

.tab-search{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: $background-dark;
}

.tab-pages{
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: right;
    font-size: small;
    align-items: center;
    padding-left: 8px;
}

.pagination{
    width: 15%;
    max-width: 110px;
    margin-right: auto;
}

.button-pages{
    margin: 4px;
}
</style>