const httpUtils = require('./http-utils.js');

function uploadMedia(collectionId, file, before, progress, success, error) {
    (async () => {
        var id = new Date().getTime().toString();
        var video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);

            before({ id, file });

            httpUtils._postFile(collectionId, file, id, progress, success, error);
        }
        video.src = URL.createObjectURL(file);
    })();
}

function getCollection(userId) {
    return new Promise((resolve, reject) => {
        var filter = {
            include: {
                relation: 'mediaGroups',
                scope: {
                    include: {
                        relation: 'medias',
                        scope: {
                            fields: {
                                version: true,
                                name: true
                            }
                        }
                    }
                }
            }
        }
        httpUtils._get(`/users/${userId}/collections?filter=${encodeURIComponent(JSON.stringify(filter))}`).then(data => {
            resolve(data[0]);
        }).catch(error => {
            reject(error);
        });
    });
}

function isThereUploads(collectionId) {
    return httpUtils._get(`/collections/${collectionId}/uploads`);
}
module.exports = { getCollection, uploadMedia, isThereUploads };