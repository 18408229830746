<template>
    <div v-if="id" class="progress">
        <canvas :id="id"></canvas>
        <div class="percent"> {{ percent }} %</div>
    </div>
</template>

<script>

export default {
    name:"VideotekProgress",
    emits:["finished"],
    props:{
        percent: Number,
        color: String,
        id: String
    },
    data() {
        return {
            canvas: undefined,
            ctx: undefined,
            old: 0,
        }
    },
    watch:{
        percent: function(oldValue, newValue) {
            if(this.percent){
                this.draw();
            }
        }
    },
    mounted() {
        this.canvas = document.getElementById(this.id);
        this.ctx = this.canvas.getContext('2d');
        this.ctx.fillStyle = this.color;
        setTimeout(this.draw, 500);
    },
    methods:{
        draw() {
            var i = this.old;
            var interval = setInterval(()=>{
                if(i >= this.canvas.width * (this.percent / 100 )){
                    clearInterval(interval);
                    this.old = i;
                    this.emit();
                }else{
                    i ++;
                    this.ctx.fillRect(0, 0, i,this.canvas.height);
                }
            }, 16);      
        },
        emit(){
            if(this.percent == 100){
                this.$emit('finished', this.id);
            }
        }
    }
}
</script>

<style lang="scss" scoped>

@import "../../assets/style.scss";

.progress{
    display: flex;
    height: 25px;
    width: 100%;
    padding: 8px;
    font-family: "Roboto", system-ui, sans-serif;
    color: #2c3e50;
    align-items: center;
}
canvas{
    height: 100%;
    border-radius: 4px;
    flex: 4;
}
.percent{
    font-size: medium;
    flex: 1;
}
</style>