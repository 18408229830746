const utils = require('./utils.js');
const i18n = require('./i18n.js');
const texts = require('../components/videotek-upload.json');

var BASE_URL = 'http://localhost:3000/api';
var UPLOAD_URL = 'http://localhost:8088';

if (process.env.NODE_ENV == 'production') {
    BASE_URL = 'https://api.videotek.weaverize.com/api';
}

function _getUrl(url) {

    var token = sessionStorage.getItem('user_token');
    var apiUrl = BASE_URL + url;

    if (token) {
        if (apiUrl.includes('?'))
            apiUrl += `&access_token=${token}`;
        else
            apiUrl += `?access_token=${token}`;
    }

    return apiUrl;
}

function fetchJson(url, config) {
    return new Promise((resolve, reject) => {
        fetch(_getUrl(url), config).then(response => {
            (async () => {
                if (response.status == 204) {
                    resolve(true);
                } else if (response.status == 200) {
                    var data = await response.json();
                    resolve(data);
                } else if (response.status == 401) {
                    var error = await response.json();
                    utils.error('Error', `${response.status} : ${error.error.message}`);
                    sessionStorage.clear();
                    console.log(response.status, error.error.message)
                    setTimeout(() => { reject() }, 1000);
                } else {
                    var error = await response.json();
                    utils.error('Error', `${response.status} : ${error.error.message}`);
                    console.log(response.status, error.error.message)
                    reject(error);
                }
            })();
        }).catch(error => {
            reject(error);
        });
    });
}

function sendRequest(method, url, body, noStringify, noHeaders) {

    var headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append('Accept', 'application/json');

    var config = { method };

    if (!noHeaders) {
        config.headers = headers;
    }
    if (body) {
        config.body = noStringify ? body : JSON.stringify(body);
    }

    return fetchJson(url, config);
}

function _get(url) {

    return sendRequest('GET', url, null);
}

function _postFile(collectionId, file, id, progress, success, error) {

    var token = sessionStorage.getItem('user_token');
    var url = `${UPLOAD_URL}/${collectionId}?access_token=${token}`;
    var xhr = new XMLHttpRequest();
    var formData = new FormData();
    formData.append('file', file);

    xhr.upload.onerror = () => {
        error(xhr.responseText);
    };
    xhr.upload.onprogress = (event) => {
        progress(id, Math.round((event.loaded / event.total) * 100));
    };

    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            success(id);
        }
    }
    xhr.open('POST', url);
    xhr.setRequestHeader('token', token);
    xhr.send(formData);
}

function _post(url, body, noStringify) {

    return sendRequest('POST', url, body, noStringify);
}

function _put(url, body, noStringify) {

    return sendRequest('PUT', url, body, noStringify);
}

function _patch(url, body, noStringify) {

    return sendRequest('PATCH', url, body, noStringify);
}

function _delete(url) {

    return sendRequest('DELETE', url, null, false);
}

module.exports = { _get, _post, _put, _patch, _delete, _postFile, _getUrl, BASE_URL };